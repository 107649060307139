<template>
  <div class="warp">
<!--    header-->
    <gw-header page="about" ref="gwheader"></gw-header>
<!--    banner-->
    <div class="case-banner">
      <img :src="util.getImageUrl('about_banner.png')" />
    </div>
<!--    关于我们-->
    <div class="container">
      <el-row class="flex-row" :gutter="100">
        <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
          <div class="about-left-item">
            <h1>关于冠通</h1>
            <p>一家国际化互联网企业，由-批具有国内著名互联网企业服务背景的精英组成，致力于为各类企业客户建设产业互联网化的基础设施平台，并围绕平台打造共享共赢的生态圈，帮助平台上的企业加强信息化建设并提升核心竞争力。</p>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15">
          <div class="about-right-item">
            <div class="about-right-item-img">
              <img :src="util.getImageUrl('abou_item1.png')" />
            </div>

            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in itemList" :key="index">
                  <h2>{{item.title}}</h2>
                  <ul>
                    <li v-for="(tagItem, tagIndex) in item.tagList" :key="tagIndex">{{tagItem.title}}</li>
                  </ul>
                </div>

              </div>
              <!-- 导航按钮 -->
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="flex-row" :gutter="100">
        <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15">
          <div class="about-right-item">
            <img :src="util.getImageUrl('abou_item2.png')" />
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
          <div class="about-left-item">
            <h1>企业文化</h1>
            <p>为中小企业注入互联网基因，并且始终致力 于打造真正适合中小企业运营使用的管理平台。</p>
            <div class="about_more">
              <span>了解更多</span>
              <img :src="util.getIconUrl('about_more_icon.png')" />
              <img :src="util.getIconUrl('about_more_icon.png')" />
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="flex-row" :gutter="100">
        <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
          <div class="about-left-item">
            <h1>我们的团队</h1>
            <p>我们始终相信“great software starts withgreat people”。冠通新创的“人”，是冠通新创交付能力最重要的组成部分。</p>
            <div class="about_con">
              <span>立即咨询</span>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15">
          <div class="about-right-item">
            <img :src="util.getImageUrl('abou_item3.png')" />
          </div>
        </el-col>

      </el-row>
    </div>
<!--    footer-->
    <gw-footer></gw-footer>
  </div>
</template>

<script>
import gwHeader from '../../components/header.vue';
import gwFooter from '../../components/footer.vue';
export default {
  name: 'About',
  components: { gwHeader, gwFooter },
  data() {
    return {
      itemList: [
        {
          title: "诚信",
          tagList: [
            { title: "遵守商业道德"},
            { title: "信守合同之约"},
            { title: "坚守公平竞争"},
            { title: "恪守友好合作"},
          ]
        },
        {
          title: "友好",
          tagList: [
            { title: "遵守商业道德"},
            { title: "信守合同之约"},
            { title: "坚守公平竞争"},
            { title: "恪守友好合作"},
          ]
        }
      ],


    }
  },
  methods: {
    jumpSolution: function (){
      this.$router.push({path: "/solutionIndex"})
    },

  },
  mounted(){
    //  初始化动画效果库
    new this.$wow.WOW({
      live: true
    }).init();
    new Swiper ('.swiper-container', {
      loop: true,
      // 如果需要分页器
      pagination: '.swiper-pagination',
      // 如果需要前进后退按钮
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      // 如果需要滚动条
      // scrollbar: '.swiper-scrollbar',
      //自动切换海报
      // autoplay: {
      //   delay: 10000,//时间 毫秒
      //   disableOnInteraction: false,//用户操作之后是否停止自动轮播默认true
      // },
    })
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .case-banner{
    width: 100%;
    height: 80rem;
    overflow: hidden;
    position: relative;
    img{
      object-fit: cover;
      width: 100%;
      display: block;
      height: 100%;
      margin: 0 auto;
    }
  }
  .flex-row{
    margin: 7rem 0 14rem;
  }
  .about-right-item-img{
    width: 100%;
    height: 29.1rem;
    overflow: hidden;
  }
  .swiper-container{
    height: auto;
    overflow: hidden;
    width: 100%;
    .swiper-wrapper{
      .swiper-slide{
        width: 100%;
        height: 100%;
        padding: 0 17% 4rem;
        box-sizing: border-box;
        h2{
          font-size: 2rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: $black-color;
          line-height: 6rem;
          margin: 0 auto;

        }
        ul{
          li{
            list-style: disc;
            font-size: 1.6rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: $black-color;
            line-height: 3rem;
          }
        }
      }
    }
  }
  .swiper-button-prev{
    left: 3rem !important;
    background-image: url("https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/gateonWeb/icon/about_left_icon.png");
    background-size: 1rem 2rem;
    top: 3rem;
  }
  .swiper-button-next{
    right: 3rem !important;
    background-image: url("https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/gateonWeb/icon/about_right_icon.png");
    background-size: 1rem 2rem;
    top: 3rem;
  }
  .about-left-item{
    h1{
      font-size: 2.4rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: $black-color;
      margin-top: 5rem !important;
    }
    p{
      font-size: 1.6rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: $black-color;
      line-height: 3rem;
      margin-top: 4rem;
    }
  }
  .about-right-item{
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 2rem;
    background: $white-color;
    box-shadow: 0px 0.8rem 1.6rem 0px rgba(102, 102, 102, 0.05);
    img{
      display: block;
      width: 100%;
      height: auto;
      transition: 0.5s;
    }
    img:hover{
      transform: scale(1.2);
    }
  }
  .about_more{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-top: 4rem;
    span{
      font-size: 1.8rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: $black-color;
      margin-right: 1rem;
    }
    img{
      width: 0.8rem;
      height: 1.2rem;
      display: block;
    }
  }
  .about_con{
    width: 10rem;
    height: 3.7rem;
    border: 0.1rem solid $text-color;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 4rem;
    margin-bottom: 3rem;
    span{
      font-size: 1.8rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: $black-color;
    }
  }
</style>
