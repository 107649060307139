<template>
  <div class="warp">
<!--    header-->
    <gw-header page="contact" ref="gwheader"></gw-header>
<!--    banner-->
    <div class="case-banner">
      <img :src="util.getImageUrl('contact_banner.png')" />
    </div>
<!--    联系我们-->
    <div class="case">
      <div class="container">
        <div class="title">
          <div>
            <h1>联系我们</h1>
            <img :src="util.getImageUrl('gt_back_logo.png')" />
          </div>
          <p>你可以通过在线聊天、语音电话或者前往冠通中心取得联系</p>
        </div>
        <el-row class="flex-row" :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="contact-item" v-for="(item,index) in contactList" :key="index">
            <div class="contact-item-wrap">
              <img :src="util.getIconUrl(item.icon)" />
              <h3>{{item.title}}</h3>
              <p>{{item.line2}}</p>
              <p>{{item.line3}}</p>
            </div>

          </el-col>
        </el-row>

      </div>
    </div>
<!--    地址-->
    <div class="address" id="address">
<!--      <img :src="util.getImageUrl('contact_map.png')" />-->
      <el-amap vid="amap" class="amap" :center="center" :zoom="zoom">
        <el-amap-marker vid="component-marker" :position="marks.position" :label="marks.label" :title="marks.title" />
      </el-amap>
    </div>
<!--    footer-->
    <gw-footer></gw-footer>
  </div>
</template>

<script>
import gwHeader from '../../components/header.vue';
import gwFooter from '../../components/footer.vue';
export default {
  name: 'Contact',
  components: { gwHeader, gwFooter },
  data() {
    return {
      contactList: [
        { title: "电话热线", icon: "contact_icon1.png", line2: "021-37511628", line3: "周一至周五 9:00-19:00"},
        { title: "电子邮件", icon: "contact_icon2.png", line2: "gateon@gateon.cn", line3: "周一至周日 9:00-22:00"},
        { title: "官方微信公众号", icon: "contact_icon3.png", line2: "关注官方微信公众号，在线", line3: " 客服沟通"},
        { title: "QQ联系", icon: "contact_icon4.png", line2: "358888888", line3: "周一至周五 9:00-19:00"},
        { title: "联系地址", icon: "contact_icon5.png", line2: "上海市奉贤区望园路2166号", line3: "周一至周五 9:00-19:00"},
      ],
      center: [121.471757, 30.969357],   // 地图初始化视图中心点
      marks: {
        position: [121.471757, 30.969357], // 定位
        label: { content: '<img src="https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/gateonWeb/image/logo.png">', offset: [-47, -40] },
        title: "冠通新创科技（上海）有限公司"
      },
      zoom: 16,


    }
  },
  methods: {
    jumpSolution: function (){
      this.$router.push({path: "/solutionIndex"})
    },

  },
  mounted(){
    //  初始化动画效果库
    new this.$wow.WOW({
      live: true
    }).init();
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .case-banner{
    width: 100%;
    height: 80rem;
    overflow: hidden;
    position: relative;
    img{
      object-fit: cover;
      width: 100%;
      display: block;
      height: 100%;
      margin: 0 auto;
    }
  }
  .title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5rem;
    div{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      h1{
        font-size: 2.8rem;
        line-height: 2.8rem;
        font-weight: bold;
        color: $black-color;
      }
      img{
        width: 6.1rem;
        height: 4.8rem;
        display: block;
        transform: translateX(-2rem);
      }
    }
    p{
      font-size: 1.4rem;
      font-weight: 400;
      color: $footer-color;
      text-align: center;
    }
  }
  .case{
    width: 100%;
    height: auto;
    overflow: hidden;
    padding-bottom: 5rem;
    background-color: $grey-color;

    .contact-item-wrap{
      width: 100%;
      height: auto;
      overflow: hidden;
      background-color: $white-color;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 1.5rem 0;
      padding: 13rem 0;
      img{
        display: block;
        width: 4.8rem;
        height: 4.8rem;
        margin-bottom: 2rem;
        transition: 0.5s;
      }
      img:hover{
        transform: rotate(360deg);
      }
      h3{
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 500;
        color: $black-color;
        margin: 0 auto;
        display: block;

      }
      p:first-of-type{
        font-size: 1.8rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: $black-color;
        line-height: 5rem;
        margin-bottom: 0 !important;
      }
      p:last-of-type{
        font-size: 1.8rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: $black-color;
        margin-bottom: 0;
        line-height: 1.8rem;
      }
    }
  }
  .address{
    width: 100%;
    padding: 5rem 0;
    height: 46.8rem;
    overflow: hidden;
    img{
      object-fit: cover;
      width: 100%;
      display: block;
      height: 100%;
      margin: 0 auto;
    }
  }



</style>
