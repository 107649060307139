<template>
  <div id="app">
    <router-view keep-alive transition transition-mode="out-in"> </router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
html {
  font-size: 10px !important;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
textarea,
input {
  margin: 0;
  padding: 0;
}
address,
cite,
dfn,
em,
var,
i {
  font-style: normal;
}
body {
  line-height: 1.5;
  font-family: "Microsoft Yahei" !important;
  /* font-family: "Microsoft Yahei", "simsun", "arial", "tahoma"; */
  color: #333;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
th {
  font-size: 100%;
  font-weight: normal;
}
button,
input,
select,
textarea {
  font-size: 100%;
}
fieldset,
img {
  border: 0;
}
a,
img {
  -webkit-touch-callout: none;
}
a,
a:active,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
}
input[type="password"],
input[type="text"],
textarea {
  resize: none;
  outline: 0;
  -webkit-appearance: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: #fff;
}
ul,
ol {
  list-style: none;
}
:focus {
  outline: none;
}
.clearfix {
  clear: both;
  content: "";
  display: block;
  overflow: hidden;
}
.clear {
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.wrap {
  width: 100%;
  height: auto;
  overflow: hidden;
}
h4,
.h4,
h5,
.h5,
h6,
.h6,
p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
h1 {
  margin: 0 !important;
  padding: 0 !important;
}
p {
  margin-bottom: 1rem;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: "Microsoft Yahei" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media only screen and (max-width: 767px) {
  html {
    font-size: 8px !important;
  }
}
.amap-marker-label {
  border: 1px solid transparent !important;
}
</style>
