<template>
  <div class="wrap">
    <!--    header-->
    <gw-header page="recruit" ref="gwheader"></gw-header>
    <div class="news-banner">
      <img :src="util.getImageUrl('recruit_banner.png')" />
      <div class="news-banner-text">
        <div class="container">
          <h2>人才招聘</h2>
          <p>伯闻天下名士，共创双赢未来。</p>
        </div>
      </div>
    </div>
    <!--  招聘内容  -->
    <div class="news">
      <div class="container">
        <el-row :gutter="100">
          <el-col :xs="24" :sm="24" :md="17" :lg="17" :xl="17">
            <el-row :gutter="30" class="news-left">
              <el-col :xs="24" :sm="24" :md="index == 0 ? 24 : 12" :lg="index == 0 ? 24 : 12" :xl="index == 0 ? 24 : 12" class="news-left-item" v-for="(item, index) in recruitList" :key="index">
                <div class="recruit-left-item" @click="util.jumpPage('recruitDetail')">
                  <h3>{{item.title}}</h3>
                  <p>经验：{{item.exp}}丨学历：{{item.education}}</p>
                  <div v-html="item.content"></div>
                  <div>
                    <span>申请职位</span>
                  </div>
                </div>

              </el-col>
            </el-row>
          </el-col>
          <el-col :md="7" :lg="7" :xl="7" class="hidden-sm-and-down">
            <div class="news-right">
              <h3>人才招聘</h3>
              <p>全部</p>
              <p>开发工程师</p>
              <p>产品经理</p>
              <p>软件测试</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!--    footer-->
    <gw-footer></gw-footer>
  </div>
</template>

<script>
import gwHeader from '../../components/header.vue';
import gwFooter from '../../components/footer.vue';
export default {
  name: "RecruitList",
  components: { gwHeader, gwFooter },
  data() {
    return {
      recruitList: [
        {
          title: "JAVA开发工程师 ",
          exp: "3-5年 ",
          education: "本科",
          content: "1、负责公司各项业务的后台开发；<br>\n" +
              "2、能够保质保量的完成工作分配任务，尊循编码规范；<br>\n" +
              "3、负责核心业务的骨干代码编写并对业务层提供基础支撑…<br>\n",
        },
        {
          title: "WEB前端开发工程师 ",
          exp: "3-5年 ",
          education: "本科",
          content: "1、精通PC端开发,有类似商城项目开发经验优先；<br>\n" +
              "2、负责mobile版及pc版的webgl大型应用程序的设计及开发；优化前端开发模式，完善前端开发规范…<br>\n",
        },
        {
          title: "Android开发工程师 ",
          exp: "3-5年 ",
          education: "本科",
          content: "1、三年以上android产品研发经验<br>\n" +
              "2、精通Java语言、Android平台开发基础；熟悉TCP、HTTP等网络协议，熟练使用数据库…<br>\n",
        },
        {
          title: "互联网高级产品经理",
          exp: "3-5年 ",
          education: "本科",
          content: "1、负责企业门店特许经营平台相关产品迭代规划及产品生命周期管理。<br>\n" +
              "2、了解连锁门店行业规则，具备业务领域的洞察力，主动了解实施项目的需求场景…<br>                       同行业的业务特点和价值，结合对市场同类产品的资料收集，对整体的交易模型和促销模型进行抽象设计规范…\n",
        },
        {
          title: "软件测试工程师",
          exp: "3-5年 ",
          education: "本科",
          content: "1、熟悉测试理论和方法，熟悉测试过程，掌握行业流行的测试技术，理解产品的能力较高，以客户眼光对产品进行测试。<br>\n" +
              "2、能够独立编写测试用例、熟练性能测试、接口测试等…<br>                    经验络协议，熟练使用数据库…\n",
        },
      ],
    }
  },
  methods: {

  },
  mounted(){
    //  初始化动画效果库
    new this.$wow.WOW({
      live: true
    }).init();
  },
}
</script>

<style lang="scss" scoped>
.news-banner{
  width: 100%;
  height: 80rem;
  overflow: hidden;
  position: relative;
  img{
    object-fit: cover;
    width: 100%;
    display: block;
    height: 100%;
    margin: 0 auto;
  }
  .news-banner-text{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    div{
      margin-top: 20rem;
      h2{
        font-size: 3.6rem;
        font-weight: 400;
        color: $white-color;
      }
      p{
        font-size: 2.4rem;
        font-weight: 400;
        color: $white-color;
      }
    }
  }
}
.news{
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 4rem 0;
  background-color: $grey-color;
}
.news-left-item:first-of-type .recruit-left-item>div:first-of-type{
  -webkit-line-clamp: 3 !important;
  height: 9rem !important;
}
.news-left{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  .news-left-item{
    margin-bottom: 4rem;
    cursor: pointer;
    .recruit-left-item{
      width: 100%;
      height: auto;
      overflow: hidden;
      box-sizing: border-box;
      background-color: $white-color;
      padding: 2rem;
      transition: 0.5s;
      h3{
        font-size: 2rem;
        margin: 0 !important;
        font-weight: bold;
        color: $black-color;
        line-height: 4rem;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        transition: 0.5s;
      }
      p{
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 3.6rem;
        color: $footer-color;
      }
      div:first-of-type{
        width: 100%;
        font-size: 1.8rem;
        line-height: 3rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: $text-color;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        height: 15rem;
      }
      div:last-of-type{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin: 3rem 0 2rem;
        span{
          width: 10.8rem;
          height: 3.4rem;
          background: linear-gradient(0deg, #057FC8, #3FB5FE);
          border-radius: 0.6rem;
          display: inline-block;
          text-align: center;
          line-height: 3.4rem;
          font-size: 1.8rem;
          font-weight: 500;
          color: $white-color;
        }
      }
    }
    .recruit-left-item:hover{
      box-shadow: 0px 6px 10px 0px rgba(102, 102, 102, 0.2);
      h3{
        color: #0B85CE;
      }
    }
  }
}
.news-right{
  h3{
    font-size: 2.2rem;
    font-weight: 500;
    color: $black-color;
    margin-bottom: 2rem;
  }
  p{
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 400;
    color: #0881CA;
    line-height: 3rem;
  }
}
.more{
  width: 25rem;
  height: 6.1rem;
  background: $white-color;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2.6rem;
  font-weight: 500;
  color: $text-color;
  margin: 4rem auto;
  border-radius: 0.4rem;
  cursor: pointer;
}

</style>
