import vueMain from './main'
const install = (Vue, vm) => {
    Vue.prototype.util = {
        getIconUrl(value) {  //获取云icon图片
            return `https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/gateonWeb/icon/${value}`
        },
        getImageUrl(value) {  //获取云图片
            return `https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/gateonWeb/image/${value}`
        },
        jumpPage(page, params){  //跳转页面--内部页面
            vueMain.$router.push({
                name: page,
                params: params
            });
        },
        jumpExternalPage(page){  //跳转页面--打开新的外部页面
            window.open (page);
        },
    }
}
export default {
    install
}

