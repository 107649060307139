<template>
  <div class="footer">
    <div class="container">
      <el-row :gutter="50">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="">
            <div class="footer-title">
              <p>您好HELLO!</p>
              <p>{{ footerMsg }}</p>
            </div>
            <div class="footer-tips">
              <div
                class="footer-tips-item"
                v-for="(item, index) in tipsList"
                :key="index"
              >
                <img :src="util.getIconUrl(item.imgUrl)" />
                <span>{{ item.text }}</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form
            :model="form"
            :rules="rules"
            ref="ruleForm"
            class="footer-right-ruleForm"
          >
            <el-form-item prop="project">
              <el-select
                v-model="form.project"
                placeholder="选择体验项目（Choice）"
                style="width: 100%"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <div class="footer-right-item">
              <el-form-item prop="userName" class="footer-form-item">
                <el-input
                  v-model="form.userName"
                  placeholder="您的姓名（Name）"
                ></el-input>
              </el-form-item>
              <el-form-item prop="email" class="footer-form-item">
                <el-input
                  v-model="form.email"
                  placeholder="邮箱（E-mail）"
                ></el-input>
              </el-form-item>
            </div>
            <div class="footer-right-item">
              <el-form-item prop="tel" class="footer-form-item">
                <el-input
                  v-model="form.tel"
                  placeholder="电话（Tel）"
                ></el-input>
              </el-form-item>
              <el-form-item prop="companyName" class="footer-form-item">
                <el-input
                  v-model="form.companyName"
                  placeholder="公司名称（Company）"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item prop="description">
              <el-input
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 10 }"
                v-model="form.description"
                placeholder="您的需求描述（Description of requirements）"
              ></el-input>
            </el-form-item>
            <el-form-item class="submit_btn">
              <el-button
                type="primary"
                class="submit_button"
                @click="submitForm('ruleForm')"
                >申请试用</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <div class="">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="6" :md="3" :lg="3" :xl="3">
            <img class="footer-logo" :src="util.getIconUrl('logo_icon.png')" />
          </el-col>
          <el-col
            :xs="6"
            :sm="6"
            :md="2"
            :lg="2"
            :xl="2"
            class="footer-tag-text-wrap"
            v-for="(item, index) in footerTagList"
            :key="index"
          >
            <span
              class="footer-tag-text"
              @click="
                item.type == 'inside'
                  ? util.jumpPage(item.page)
                  : util.jumpExternalPage(item.page)
              "
              >{{ item.title }}</span
            >
          </el-col>
        </el-row>
      </div>
      <div class="footer-copy">
        <p>隐私权政策 · 使用条款 · 合作伙伴 · 网站问题反馈</p>
        <p>
          Copyright © 2016-2021 GATEON 冠通新创科技（上海）有限公司 版权所有
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >沪ICP备16018891号-1</a
          >
        </p>

        <a
          href="https://xyt.xcc.cn/getpcInfo?sn=1478266450936606720&language=CN&certType=8&url=*.gateon.cn"
          target="_blank"
          style="position: relative;display: inline-block"
        >
          <div
            style="width:100%;height:100%;position: absolute;top: 0;left: 0;"
          ></div>
          <embed
            src="https://program.xinchacha.com/web/1478266450936606720=*.gateon.cn.svg"
            width="103"
            height="38"
            type="image/svg+xml"
            pluginspage="//www.adobe.com/svg/viewer/install/"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "gwFooter",
  data() {
    return {
      footerMsg:
        "感谢您关注冠通新创科技（上海）有限公司，若您有合作意向，请留言体验或使用以下方式联系我们，我们将尽快给您回复，为您提供真诚的服务，谢谢！",
      tipsList: [
        { imgUrl: "tel_icon.png", text: "电话：021-37511628    021-57158898" },
        { imgUrl: "email_icon.png", text: "邮箱：gateon@gateon.cn" },
        { imgUrl: "address_icon.png", text: "地址：上海市奉贤区望园路2166号" },
      ],
      projectList: [
        { label: "社群电商", value: "社群电商" },
        { label: "云监管平台", value: "云监管平台" },
        { label: "菌宝家族", value: "菌宝家族" },
        { label: "萌百格", value: "萌百格" },
      ],
      form: {
        project: "",
        userName: "",
        email: "",
        tel: "",
        companyName: "",
        description: "",
      },
      rules: {
        project: [
          { required: true, message: "请选择体验项目", trigger: "change" },
        ],
        userName: [
          { required: true, message: "请输入您的姓名", trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入您的邮箱地址", trigger: "blur" },
        ],
        tel: [
          { required: true, message: "请输入您的电话号码", trigger: "blur" },
        ],
        companyName: [
          { required: true, message: "请输入您的公司名称", trigger: "blur" },
        ],
        description: [
          { required: true, message: "请输入您的需求描述", trigger: "blur" },
        ],
      },
      footerTagList: [
        { title: "关于我们", page: "aboutIndex", type: "inside" },
        { title: "联系我们", page: "contactIndex", type: "inside" },
        { title: "招聘精英", page: "recruitList", type: "inside" },
        {
          title: "门店零售SaaS",
          page: "http://www.mengbaige.com/",
          type: "external",
        },
        {
          title: "妈咪全知道",
          page: "http://www.momwow.com.cn",
          type: "external",
        },
        { title: "晨冠集团", page: "http://www.chgry.com/", type: "external" },
      ],
    };
  },
  mounted() {
    //  初始化动画效果库
    new this.$wow.WOW({
      live: true,
    }).init();
  },
  methods: {
    //表单提交
    submitForm: function(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 5rem 0;
  background-color: $footer-back-color;
  .footer-title {
    width: 97%;
    height: auto;
    overflow: hidden;
    p {
      font-size: 1.4rem;
      font-weight: 500;
      color: $footer-color;
    }
  }
  .footer-tips {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-top: 1rem;
    margin-bottom: 2rem;
    .footer-tips-item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 1rem 0;
      img {
        display: block;
        width: 3.2rem;
        height: 3.2rem;
        margin-right: 1rem;
      }
      span {
        font-size: 1.4rem;
        font-weight: 500;
        color: $footer-color;
      }
    }
  }
}
.footer-right-ruleForm {
  width: 100%;
  box-sizing: border-box;
  //padding-left: 3%;
  height: auto;
  overflow: hidden;
  display: block;
  .footer-right-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
/deep/.el-input__inner,
/deep/.el-textarea__inner {
  background-color: #353535 !important;
  border: none !important;
  color: #ffffff !important;
}
.footer-form-item {
  width: 45%;
}
.submit_btn {
  display: flex;
  justify-content: flex-end;
  .submit_button {
    width: 7.8rem;
    height: 2.4rem;
    background: linear-gradient(0deg, #057fc8, #3fb5fe);
    border-radius: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 1.4rem;
    font-weight: 500;
    color: $white-color;
  }
}
.footer-tag-text {
  font-size: 1.3rem;
  font-weight: 500;
  color: $footer-color;
  line-height: 2.6rem;
  cursor: pointer;
}
.footer-logo {
  display: block;
  margin: 0 auto 1rem;
}
.footer-copy {
  width: 100%;
  border-top: 0.1rem solid #353535;
  margin-top: 2rem;
  padding-top: 2rem;
  p {
    color: $text-color;
    font-size: 1.3rem;
    a {
      color: $text-color;
      font-size: 1.3rem;
    }
  }
}
.footer-tag-text-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
