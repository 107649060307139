import Vue from 'vue'
import App from './App.vue'

//引入路由
import VueRouter from 'vue-router'
import Routes from "./router.js"
const router = new VueRouter({
  routes: Routes
})
//路由跳转之前监听守卫
router.beforeEach((to, from, next) => {
//  路由变化时更改页面title
  if(to.meta.title){
    document.title = to.meta.title
  }
  next()
})

//路由跳转之后监听守卫
router.afterEach(() => {
  //页面跳转后滚动条重置至顶部
  window.scrollTo(0,0);
})

//解决跳转当前页面报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

//引入bootstrap
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.min.js'
Vue.use(VueRouter)

//引入工具类
import utils from './utils';
Vue.use(utils, app)

//引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
Vue.use(ElementUI);

//引入wow.js动画效果库
import wow from 'wowjs'
import 'wowjs/css/libs/animate.css'
Vue.prototype.$wow=wow

//引入swiper轮播图组件
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

//引入高德地图组件
import VueAMap from "vue-amap";
Vue.use(VueAMap);
// 初始化vue-amap
VueAMap.initAMapApiLoader({
  key: "f09945f866835890ac498577f3a7c3c6", // 这里写你申请的高德地图的key
  plugin: ["AMap.Autocomplete", "AMap.Geocoder", "AMap.Geolocation"],
  v: "1.4.15",
  uiVersion: "1.1"
});

Vue.config.productionTip = false

let vueMain = new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')

export default vueMain;

