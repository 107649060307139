<template>
  <div>
    <div class="header" :style="{backgroundColor: theme == 'black' ? 'transparent' : '#ffffff'}">
      <!--头部内容-->
      <div class="header-content hidden-sm-and-down">
        <div class="header_left">
          <div class="header-logo" @click="util.jumpPage('home')">
            <img :src="util.getImageUrl(theme == 'black' ? 'logo_white.png' : 'logo.png')" />
          </div>
          <div class="header-nav">
            <span :style="{color: theme == 'black' ? '#ffffff' : '#333333'}" v-for="(item, index) in navList" :key="index" @mouseover="navHover(item.nav, index)" @mouseout="navOut(item.nav, index)" :class="item.showLine || page === item.nav ? 'show-bottom-line' : ''" @click="util.jumpPage(item.page)">{{item.title}}</span>
          </div>
        </div>
        <div class="header-right">
          <img @click="showOrHideSearch()" :src="util.getIconUrl(theme == 'black' ? 'search_white_icon.png' : 'search_icon.png')" />
          <div v-if="theme == 'white'" @click="goToFooter()">
            <span>申请试用</span>
            <img :src="util.getIconUrl('right_icon.png')" />
          </div>
          <div v-else-if="theme == 'black'" @click="goToFooter()" class="black-right">
            <span>申请试用</span>
            <img :src="util.getIconUrl('right_icon.png')" />
          </div>
        </div>
      </div>

      <!--  手机端导航栏  -->
      <div class="mobile-nav hidden-md-and-up">
        <div class="mobile-nav-icon">
          <img class="mobile-nav-logo" @click="util.jumpPage('home')" :src="util.getImageUrl(theme == 'black' ? 'logo_white.png' : 'logo.png')" />
          <img id="nav-icon" @click="showOrHideMobileNavList()" class="mobile-nav-n-icon" :src="util.getIconUrl(theme == 'black' ? 'mobile_nav_icon.png' : 'mobile_nav_blue_icon.png')" />
        </div>
        <ul class="sidenav" :class="showMobileNav ? 'showMobileNav' : ''">
          <li v-for="(item, index) in navList" :class="showMobileNav ? item.cla+' mobile-nav-text' : ''" :key="index">
            <el-dropdown v-if="item.nav == 'solution'" trigger="click" @command="goToSolution">
              <span style="color: #FFFFFF; font-size: 1.8rem">{{item.title}}</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(sItem, sIndex) in solutionList" :key="sIndex" :command="sItem.details">{{sItem.title}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <span v-else @click="util.jumpPage(item.page)">{{item.title}}</span>
          </li>
        </ul>
        <div class="mobile-nav-left-empty" v-if="showMobileNav" @click="showOrHideMobileNavList()"></div>
      </div>
<!--      搜索-->
      <div class="container" v-if="showSearch">
        <div class="serach-wrap">
          <img :src="util.getIconUrl('search_gray_icon.png')" />
          <el-input v-model="keyWords" class="search-input" :placeholder="keyWordsTips"></el-input>
        </div>
      </div>

      <div class="item-line" v-if="theme != 'black'"></div>
      <!--解决方案-->
      <div class="solution-page hidden-sm-and-down" @mouseover="navHover('solution', 0)" @mouseout="navOut('solution', 0)" :class="showSolution ? 'show-solution-page' : ''">
        <div class="container">
          <el-row>
            <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="8" class="solution-item" v-for="(item, index) in solutionList" :key="index">
              <div class="solution-item-wrap">
                <div class="solution-item-left">
                  <img @click="jump(item.details)" :src="util.getImageUrl(item.imgUrl)">
                </div>
                <div class="solution-item-right">
<!--                  <div @click="util.jumpPage('solutionIndex', {details: JSON.stringify(item.details)})">-->
                  <div @click="jump(item.details)">
                    <p>{{item.title}}</p>
                    <p>{{item.subTitle}}</p>
                  </div>
                  <div>
                    <p v-for="(subItem, subIndex) in item.tagList" :key="subIndex" @click="jump(item.details)">{{subItem.title}}</p>
                  </div>
                </div>
              </div>

            </el-col>
          </el-row>
        </div>
      </div>

    </div>
    <div v-if="page != 'home'" :style="{height: showSearch ? '14.4rem' : '6.5rem'}" class="block"></div>
  </div>

</template>

<script>
export default {
  name: 'gwHeader',
  props: {
    page: String
  },
  data() {
    return {
      theme: "white",  //主题
      showSearch: false,  //是否展示搜索框
      navList: [  //导航列表
        { title: "解决方案", nav: "solution", showLine: false, cla: "mobile-nav-text-item1", page: "solutionIndex"},
        { title: "客户案例", nav: "case", showLine: false, cla: "mobile-nav-text-item2", page: "caseIndex"},
        { title: "新闻资讯", nav: "news", showLine: false, cla: "mobile-nav-text-item3", page: "newsList"},
        { title: "人才招聘", nav: "recruit", showLine: false, cla: "mobile-nav-text-item4", page: "recruitList"},
        { title: "关于我们", nav: "about", showLine: false, cla: "mobile-nav-text-item5", page: "aboutIndex"},
        { title: "联系我们", nav: "contact", showLine: false, cla: "mobile-nav-text-item6", page: "contactIndex"},
      ],
      keyWords: "",  //搜索关键字
      keyWordsTips: "请输入关键字",  //搜索框提示文字
      showMobileNav: false,  //是否显示手机端导航
      showSolution: false,  //是否显示解决方案
      solutionList: [  //解决方案列表
        {
          title: "销帮主医疗器械经营管理平台",
          subTitle: "“规范+便捷”的医疗器械经营企业服务...",
          imgUrl: "solution1.png",
          tagList: [
            { title: "进销存管理"},
            { title: "数据云对接"},
            { title: "系统设计依据"}
          ],
          details: {
            banner: "xbz_banner.png",
            topTitle: "销帮主医疗器械经营管理平台",
            topSubTitle: "“规范+便捷”的医疗器械经营企业服务平台",
            swiperImgList: [
              { imgUrl: "xbz_swipe_one.png"},
              { imgUrl: "xbz_swipe_two.png"},
              { imgUrl: "xbz_swipe_three.png"},
            ],
            itemList: [
              { imgPosition: "left", imgUrl: "xbz_solution_one.png", title: "进销存管理", text: "1.对货、单、款的连动全面管理<br>\n" +
                    "2.对生产企业、供货企业、购货企业以及本  企业的经营资\n" +
                    "   格及经营范围进行全面管理<br>\n" +
                    "3.有效预警管理，提前报警，超限禁用<br>\n" +
                    "4.对货品的从采购到库存再到销售进行全链条单据管理并留  \n" +
                    "   有有效操作痕迹", qrcode: null, tips: "微信扫码体验小程序"},
              { imgPosition: "right", imgUrl: "xbz_solution_two.png", title: "数据云对接", text: "1.  监管平台轻松对接<br>\n" +
                    "2.  数据信息实时管控<br>\n" +
                    "3.  异地化多点办公<br>", qrcode: null, tips: ""},
              { imgPosition: "left", imgUrl: "xbz_solution_three.png", title: "系统设计依据", text: "1.《医疗器械经营监督管理办法》（国家食品药品监督管理\n" +
                    "   总局令第8号）<br>\n" +
                    "2.《医疗器械监督管理条例》（国务院令第650号）<br>\n" +
                    "3.医疗器械监管管理条例-2017年修订版 沪（2015）785号 \n" +
                    "   《上海市医疗器械经营质量管理规范实施细则》<br>\n" +
                    "4.沪[2015]786号《上海市医疗器械经营质量管理规范实施\n" +
                    "   细则评定内容及检查要点》<br>", qrcode: null, tips: ""},
            ],
            smallLogo: "xbz_advertising_logo.png",
            logoFooterTitle: "“规范+便捷”的医疗器械经营企业服务平台",
            picImgUrl: "xbz_advertising_pic.png",
            bottomTitle: "销帮主医疗器械经营管理平台",
            bottomSubTitle: "应有尽有",
            bigLogo: "xbz_footer_logo.png",
            tagList:[
              { title: "节约成本"},
              { title: "使用便捷"},
              { title: "功能共享"},
              { title: "弹性扩展"},
              { title: "云端升级"},
            ],
            footerTips: "由于项目多为个性定制化，实际价格区间以咨询结果为参考",
          },
        },
        {
          title: "母婴特许经营加盟解决方案",
          subTitle: "母婴家庭智慧零售连锁平台",
          imgUrl: "solution2.png",
          tagList: [
            { title: "线上云店"},
            { title: "店务助手"},
            { title: "智慧零售"},
            { title: "后台管理"}
          ],
          details: {
            banner: "momwow_banner.png?version=1",
            topTitle: "母婴特许经营加盟解决方案",
            topSubTitle: "母婴家庭智慧零售连锁平台",
            swiperImgList: [
              { imgUrl: "momwow_swipe_one.png"},
              { imgUrl: "momwow_swipe_two.png"},
              { imgUrl: "momwow_swipe_three.png"},
            ],
            itemList: [
              { imgPosition: "left", imgUrl: "momwow_solution_one.png", title: "线上云店", text: "多样营销玩法，助力门店构建思域流量池，迅速拓客拉新，快速实现交易变现。", qrcode: "momwow_qr1.png", tips: "微信扫码体验小程序"},
              { imgPosition: "right", imgUrl: "momwow_solution_two.png", title: "店务助手", text: "门店智能管理工具，精准营销，精细库存，会员服务，营业分析，门店数据实时触达，随时随地管理门店。", qrcode: null, tips: ""},
              { imgPosition: "left", imgUrl: "momwow_solution_three.png", title: "智慧零售", text: "稳定高效的收银设备，操作简易，快速上手，赋能导购轻松收银。", qrcode: null, tips: ""},
              { imgPosition: "right", imgUrl: "momwow_solution_four.png", title: "后台管理", text: "打通商品、库存、订单、会员、储值、营销、资产、报表八大模块，数据全面互通，线上线下一体化", qrcode: null, tips: ""},
            ],
            smallLogo: "momwow_advertising_logo.png",
            logoFooterTitle: "母婴家庭智慧零售连锁平台",
            picImgUrl: "momwow_advertising_pic.png",
            bottomTitle: "母婴特许经营加盟解决方案",
            bottomSubTitle: "应有尽有",
            bigLogo: "momwow_footer_logo.png",
            tagList:[
              { title: "在线商城"},
              { title: "进销存"},
              { title: "裂变分销"},
              { title: "拼团秒杀"},
              { title: "会员营销"},
            ],
            footerTips: "由于项目多为个性定制化，实际价格区间以咨询结果为参考",
          },
        },
        {
          title: "萌百格新零售解决方案",
          subTitle: "线上线下结合  C端B端共进",
          imgUrl: "solution3.png",
          tagList: [
            { title: "萌百小店"},
            { title: "萌百格店务助手"},
            { title: "直播"}
          ],
          details: {
            banner: "mengbaige_banner.png",
            topTitle: "新零售解决方案",
            topSubTitle: "赋能线下门店，重构消费链接",
            swiperImgList: [
              { imgUrl: "mengbaige_swipe_one.png"},
              { imgUrl: "mengbaige_swipe_two.png"},
              { imgUrl: "mengbaige_swipe_three.png"},
            ],
            itemList: [
              { imgPosition: "left", imgUrl: "mengbaige_solution_one.png", title: "线上云店", text: "门店专属线上线下小程序，电商+直播，搭建移动电商平台", qrcode: null, tips: ""},
              { imgPosition: "right", imgUrl: "mengbaige_solution_two.png", title: "店务助手", text: "门店移动管理工具，销售对账、商品盘点、会员跟踪、营业数据，方便快捷管理门店", qrcode: null, tips: ""},
              { imgPosition: "left", imgUrl: "mengbaige_solution_three.png", title: "门店收银", text: "快捷收银，高效稳定，操作简易", qrcode: null, tips: ""},
              { imgPosition: "right", imgUrl: "mengbaige_solution_four.png", title: "SAAS后台", text: "提供门店管理、商品库存、会员管理、营销工具等多模块功能，人货场数据全部打通", qrcode: null, tips: ""},
            ],
            smallLogo: "mengbaige_advertising_logo.png",
            logoFooterTitle: "母婴家庭智慧零售连锁平台",
            picImgUrl: "mengbaige_advertising_pic.png",
            bottomTitle: "新零售解决方案",
            bottomSubTitle: "零售改变 从新开始",
            bigLogo: "mengbaige_footer_logo.png",
            tagList:[
              { title: "智能监控"},
              { title: "数据经营"},
              { title: "实时管理"},
              { title: "拼团秒杀"},
              { title: "多种营销"},
            ],
            footerTips: "由于项目多为个性定制化，实际价格区间以咨询结果为准",
          },
        },
        {
          title: "生产行业经销解决方案",
          subTitle: "数字营销平台",
          imgUrl: "solution4.png",
          tagList: [
            { title: "宠妈荟小程序"},
            { title: "销旺财小程序"},
            { title: "后台管理"}
          ],
          details: {
            banner: "hikid_banner.png",
            topTitle: "生产行业经销解决方案",
            topSubTitle: "数字营销平台",
            swiperImgList: [
              { imgUrl: "hikid_swipe_one.png"},
              { imgUrl: "hikid_swipe_two.png"},
              { imgUrl: "hikid_swipe_three.png"},
            ],
            itemList: [
              { imgPosition: "left", imgUrl: "hikid_solution_one.png", title: "宠妈荟小程序", text: "多样营销玩法，助力门店构建思域流量池，迅速拓客拉新", qrcode: "hikid_qr1.png", tips: "微信扫码体验小程序"},
              { imgPosition: "right", imgUrl: "hikid_solution_two.png", title: "销旺财小程序", text: "销售、经销商、门店管理工具，销售分析，会员报表", qrcode: "hikid_qr2.png", tips: "微信扫码体验小程序"},
              { imgPosition: "left", imgUrl: "hikid_solution_three.png", title: "后台管理", text: "打通商品、会员、呼叫中心、营销、商户、报表六大模块，数据全面互通，线上线下一体化", qrcode: null, tips: ""},
            ],
            smallLogo: "hikid_advertising_logo.png",
            logoFooterTitle: "DMP晨冠生物数字营销平台",
            picImgUrl: "hikid_advertising_pic.png",
            bottomTitle: "生产行业经销解决方案",
            bottomSubTitle: "应有尽有",
            bigLogo: "hikid_footer_logo.png",
            tagList:[
              { title: "积分商城"},
              { title: "营销活动"},
              { title: "会员营销"},
            ],
            footerTips: "由于项目多为个性定制化，实际价格区间以咨询结果为准",
          },
        },
        {
          title: "零售行业礼品营销解决方案",
          subTitle: "让小店营销更简单有效",
          imgUrl: "solution5.png",
          tagList: [
            { title: "礼品商城"},
            { title: "营销工具"},
            { title: "玩法方案"}
          ],
          details: {
            banner: "wanlihui_banner.png",
            topTitle: "零售行业礼品营销解决方案",
            topSubTitle: "让小店营销更简单有效",
            swiperImgList: [
              { imgUrl: "wanlihui_swipe_one.png"},
              { imgUrl: "wanlihui_swipe_two.png"},
              { imgUrl: "wanlihui_swipe_three.png"},
            ],
            itemList: [
              { imgPosition: "left", imgUrl: "wanlihui_solution_one.png", title: "礼品商城", text: "分类目分行业选品，避免海量商品干扰，筛选大牌合作，力保源头好货，质优价美。", qrcode: "wanlihui_qr1.png", tips: "微信扫码体验小程序"},
              { imgPosition: "right", imgUrl: "wanlihui_solution_two.png", title: "营销工具", text: "符合新生代习惯，简单可执行落地", qrcode: null, tips: "微信扫码体验小程序"},
              { imgPosition: "left", imgUrl: "wanlihui_solution_three.png", title: "玩法方案", text: "定期策划活动模板，简易执行", qrcode: null, tips: ""},
            ],
            smallLogo: "wanlihui_advertising_logo.png",
            logoFooterTitle: "让小店营销更简单有效",
            picImgUrl: "wanlihui_advertising_pic.png",
            bottomTitle: "零售行业礼品营销解决方案",
            bottomSubTitle: "应有尽有",
            bigLogo: "wanlihui_footer_logo.png",
            tagList:[
              { title: "在线商城"},
              { title: "营销工具"},
              { title: "定制营销方案"},
            ],
            footerTips: "由于项目多为个性定制化，实际价格区间以咨询结果为准",
          },
        },
        {
          title: "社群营销解决方案",
          subTitle: "社群营销解决方案",
          imgUrl: "solution6.png",
          tagList: [
            { title: "智慧新零售创业平台"},
            { title: "新“店”商"},
            { title: "超级运营策略"},
            { title: "超级流量池玩法"},
            { title: "超级运营法则"},
          ],
          details: {
            banner: "florafit_banner.png",
            topTitle: "社群营销解决方案",
            topSubTitle: "菌临天下",
            swiperImgList: [
              { imgUrl: "florafit_swipe_one.png"},
              { imgUrl: "florafit_swipe_two.png"},
              { imgUrl: "florafit_swipe_three.png"},
            ],
            itemList: [
              { imgPosition: "left", imgUrl: "florafit_solution_one.png", title: "智慧新零售创业平台", text: "以互联网为依托，通过运用大数据、人工智能等先进技术手段，对商品的生产、流通与销售过程进行升级改造，进而重塑业态结构与生态圈，并对线上服务、线下体验以及现代物流进行深度融合的零售新模式。", qrcode: "florafit_qr1.png", tips: "微信扫码体验小程序"},
              { imgPosition: "right", imgUrl: "florafit_solution_two.png", title: "新“店”商", text: "让客户即是消费者也是销售者<br>\n" +
                    "降低门店人工成本  <br>\n" +
                    "通过社交链条，无限拓展实体业务  提高零售效率<br>\n" +
                    "同时，助力中国1000万宝妈就业创业<br>", qrcode: null, tips: "微信扫码体验小程序"},
              { imgPosition: "left", imgUrl: "florafit_solution_three.png", title: "超级运营策略", text: "需求洞察：找准用户痛点<br>\n" +
                    "流量积蓄：裂变和社群打造流量池<br>\n" +
                    "价值服务：短视频、直播、云课程训练营塑造口碑<br>\n" +
                    "体验转化：诊断和体验课赢得信任<br>", qrcode: null, tips: ""},
              { imgPosition: "right", imgUrl: "florafit_solution_four.png", title: "超级流量池玩法", text: "社群+小程序+短视频+直播+私域流程", qrcode: null, tips: ""},
              { imgPosition: "left", imgUrl: "florafit_solution_five.png", title: "超级运营法则", text: "超级IP： 建立品牌人格，拉进用户距离<br>\n" +
                    "超级链接：设计长期价值，吸引用户关注<br>\n" +
                    "超级促活：创造用户触点，保持互动曝光<br>\n" +
                    "用户分层：建立用户标签，实现精准营销<br>\n" +
                    "超级复购：引导用户需求，创造二次成交<br>", qrcode: null, tips: ""},
            ],
            smallLogo: "florafit_advertising_logo.png",
            logoFooterTitle: "菌临天下",
            picImgUrl: "florafit_advertising_pic.png",
            bottomTitle: "社群营销解决方案",
            bottomSubTitle: "应有尽有",
            bigLogo: "florafit_footer_logo.png",
            tagList:[
              { title: "在线商城"},
              { title: "裂变分销"},
              { title: "拼团秒杀"},
              { title: "会员营销"},
              { title: "社群管理"},
            ],
            footerTips: "由于项目多为个性定制化，实际价格区间以咨询结果为准",
          },
        },
      ],
    }
  },
  created() {
    if(this.page === 'home'){
      this.theme = 'black';
    }else{
      this.theme = 'white';
    }
    //当页面为招聘时显示搜索框
    if(this.page === 'recruit'){
      this.showSearch = true;
      this.keyWordsTips = "搜索工作岗位";
    }
  },
  mounted() {
    //  初始化动画效果库
    new this.$wow.WOW({
      live: true
    }).init();
  },
  methods: {
    //鼠标移动到导航上
    navHover: function (nav, index){
      //鼠标移动到哪个菜单，哪个菜单显示下划线
      this.navList.map((item) => {
        item.showLine = false;
      })
      this.navList[index].showLine = true;
      if(nav == 'solution'){  //鼠标移动至解决方案时显示解决方案菜单
        this.showSolution = true;
          this.theme = 'white';
      }

    },
    //鼠标离开导航
    navOut: function (nav, index){
      //隐藏离开的菜单下划线
      this.navList[index].showLine = false;
      this.showSolution = false;
      if(this.page === 'home'){
          this.theme = 'black';
      }

    },
    //显示或隐藏手机端导航菜单
    showOrHideMobileNavList: function (){
      this.showMobileNav = !this.showMobileNav;
    },
    //显示或隐藏搜索框
    showOrHideSearch: function (){
      this.showSearch = !this.showSearch;
    },
    //手机端跳转至解决方案
    goToSolution: function (command){
      let currentRoute = this.$route.name;
      if(currentRoute == 'solutionIndex'){
        //跳转当前页面
        this.$router.replace({
          name: "refresh",
          params: {details: JSON.stringify(command)}
        })
      }else{
        //正常跳转
        this.util.jumpPage('solutionIndex', {details: JSON.stringify(command)});
      }
    },
    //跳转至解决方案
    jump: function (details){
      let currentRoute = this.$route.name;
      if(currentRoute == 'solutionIndex'){
        //跳转当前页面
        this.$router.replace({
          name: "refresh",
          params: {details: JSON.stringify(details)}
        })
      }else{
        //正常跳转
        this.util.jumpPage('solutionIndex', {details: JSON.stringify(details)});
      }
    },
    //滚动到页面最底部
    goToFooter: function(){
      window.scrollTo(0,50000);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .serach-wrap{
    width: 100%;
    height: 4.8rem;
    background: #F2F2F2;
    border-radius: 0.4rem;
    margin: 1rem 0 2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    img{
      margin-left: 1.5rem;
      display: block;
      width: 2rem;
      height: 2rem;
    }
    /deep/.el-input__inner{
      border: none !important;
      background-color: transparent;
    }
  }
  .header{
    width: 100%;
    height: auto;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    .header-content{
      height: 6.5rem;
      box-sizing: border-box;
      position: relative;
      z-index: 99;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .header_left{
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .header-logo{
          width: 10.5rem;
          height: 2.6rem;
          margin-right: 3rem;
          cursor: pointer;
          img{
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        .header-nav{
          height: 100%;
          display: flex;
          flex-direction: row;
          span{
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 3rem;
            height: 100%;
            font-size: 1.4rem;
            font-weight: bold;
            color: $black-color;
            border-bottom: 0.3rem solid transparent;
          }
          .show-bottom-line{
            border-bottom: 0.3rem solid $theme-color;
            cursor: pointer;
          }
        }
      }
      .header-right{
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        img{
          display: block;
          width: 2rem;
          height: 2rem;
          cursor: pointer;
        }
        div{
          width: 7.8rem;
          height: 3rem;
          cursor: pointer;
          margin-left: 4rem;
          border-radius: 0.4rem;
          background: linear-gradient(0deg, #057FC8, #3FB5FE);
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          span{
            font-size: 1.4rem;
            font-weight: 500;
            color: $white-color;
          }
          img{
            display: block;
            width: 0.5rem;
            height: 0.8rem;
            margin-left: 0.5rem;
          }
        }
        .black-right{
          width: 7.8rem;
          height: 3rem;
          border: 0.1rem solid $white-color;
          border-radius: 0.4rem;
          background: transparent !important;
          cursor: pointer;
          span{
            font-size: 1.4rem;
            font-weight: 500;
            color: $white-color;
          }
        }
      }
    }
    .show-solution-page{
      top: 6.5rem !important;
    }
    .solution-page{
      width: 100%;
      //padding: 0 calc((100% - 120rem) / 2);
      height: auto;
      overflow: hidden;
      position: fixed;
      top: -100rem;
      left: 0;
      z-index: 9;
      background-color: $white-color;
      //margin-left: -60rem;
      transition: 0.5s;
      .solution-item{
        height: 24rem;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        .solution-item-wrap{
          width: calc(100% - 5rem);
          box-sizing: border-box;
          height: calc(100% - 5rem);
          overflow: hidden;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          .solution-item-left{
            width: 9rem;
            height: auto;
            overflow: hidden;
            margin-right: 3rem;
            cursor: pointer;
            img{
              display: block;
              width: 100%;
              height: auto;
            }
          }
          .solution-item-right{
            div:first-of-type{
              margin-bottom: 3rem;
              cursor: pointer;
              p:first-of-type{
                font-size: 1.6rem;
                line-height: 1.6rem;
                font-weight: bold;
                color: $black-color;
              }
              p:nth-of-type(2){
                font-size: 1.2rem;
                line-height: 1.2rem;
                font-weight: 400;
                color: $footer-color;
              }
            }
            div:nth-of-type(2){
              cursor: pointer;
              p{
                font-size: 1.4rem;
                line-height: 1.4rem;
                color: $black-color;
                font-weight: 400;
              }
            }
          }
        }

      }


    }
  }
  .block{
    width: 100%;
    height: 6.5rem;
  }
  .item-line{
    width: 100%;
    height: 0.1rem;
    background-color: #F2F2F2;
  }
// xl  屏幕较大设备
  @media only screen and (min-width: 1920px){
    .solution-item:nth-of-type(1),.solution-item:nth-of-type(2),.solution-item:nth-of-type(4),.solution-item:nth-of-type(5){
      border-right: 0.1rem solid #F2F2F2;
    }
    .solution-item:nth-of-type(3),.solution-item:nth-of-type(6){
      border-right: 0.1rem solid transparent;
    }
    .header-content{
      width: 120rem;
    }
  }
// lg  一般电脑设备
  @media only screen and (min-width: 1200px){
    .solution-item:nth-of-type(1),.solution-item:nth-of-type(2),.solution-item:nth-of-type(4),.solution-item:nth-of-type(5){
      border-right: 0.1rem solid #F2F2F2 !important;
    }
    .solution-item:nth-of-type(3),.solution-item:nth-of-type(6){
      border-right: 0.1rem solid transparent !important;
    }
    .solution-item:nth-of-type(4),.solution-item:nth-of-type(5),.solution-item:nth-of-type(6){
      border-top: 0.1rem solid #F2F2F2 !important;
    }
    .header-content{
      width: 120rem !important;
    }
  }
// md  平板设备
  @media only screen and (min-width: 992px){
    .solution-item:nth-of-type(2),.solution-item:nth-of-type(4),.solution-item:nth-of-type(6){
      border-right: 0.1rem solid transparent;
    }
    .solution-item:nth-of-type(1),.solution-item:nth-of-type(3),.solution-item:nth-of-type(5){
      border-right: 0.1rem solid #F2F2F2;
    }
    .header{
      overflow: inherit !important;
    }
    .header-content{
      width: 98%;
      margin: 0 auto;
    }
  }
//  sm  手机设备
  @media only screen and (min-width: 768px){
    .header{
      overflow: inherit !important;
    }
  }
//  xs  小屏手机设备
  @media only screen and (max-width: 767px){
    .header{
      overflow: inherit !important;
    }
  }
  .mobile-nav{
    width: 100%;
    height: auto;
    //overflow: hidden;
    position: relative;
  }
  .mobile-nav-icon{
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
    .mobile-nav-logo{

    }
    .mobile-nav-n-icon{
      width: 3rem;
      display: block;
      height: auto;
    }
  }
  .mobile-nav-left-empty{
    width: calc(100% - 15rem);
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
  }
  .sidenav {
    height: 100vh;
    background: rgb(50, 60, 60);
    width: 15rem;
    box-sizing: border-box;
    margin-bottom: 0;
    position: absolute; right: -15rem; top: 0; bottom: 0;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .showMobileNav{
    right: 0 !important;
  }
  .sidenav li {
    list-style-type: none;
    color: $white-color;
    font: bold 1.2rem/4.8rem Montserrat;
    opacity: 0;
    transform: translateX(5rem);
    transition: all 0.4s;
    display: block;
    text-align: center;
    height: 6.8rem;
    width: 100%;
    font-size: 1.8rem;
    line-height: 6.8rem;
  }
  .transX15{
    transform: translateX(15rem);
  }
  .mobile-nav-text{
    opacity: 1 !important;
    transform: translateX(0) !important;
  }
  .mobile-nav-text-item1{
    transition-delay: 0.08s !important;
  }
  .mobile-nav-text-item2{
    transition-delay: 0.16s !important;
  }
  .mobile-nav-text-item3{
    transition-delay: 0.24s !important;
  }
  .mobile-nav-text-item4{
    transition-delay: 0.32s !important;
  }
  .mobile-nav-text-item5{
    transition-delay: 0.40s !important;
  }
  .mobile-nav-text-item6{
    transition-delay: 0.48s !important;
  }



</style>
