<template>
  <div class="wrap">
    <!--    header-->
    <gw-header page="news" ref="gwheader"></gw-header>
    <div class="news-banner">
      <img :src="util.getImageUrl('news_banner.png')" />
      <div class="news-banner-text">
        <div class="container">
          <h2>新闻&媒体</h2>
          <p>新闻每一天在这里发生</p>
          <p>我们一起看世界</p>
        </div>
      </div>
    </div>
    <!--  新闻内容  -->
    <div class="news">
      <div class="container">
        <el-row :gutter="100">
          <el-col :xs="24" :sm="24" :md="17" :lg="17" :xl="17">
            <el-row :gutter="30" class="news-left">
              <el-col :xs="24" :sm="24" :md="index == 0 ? 24 : 12" :lg="index == 0 ? 24 : 12" :xl="index == 0 ? 24 : 12" class="news-left-item" v-for="(item, index) in newsList" :key="index">
                <div class="news-left-item-image" @click="util.jumpPage('newsDetail')">
                  <img :src="util.getImageUrl(item.imgUrl)" />
                </div>
                <div class="news-left-item-text" @click="util.jumpPage('newsDetail')">
                  <h5>{{item.title}}</h5>
                  <span>{{item.datetime}}</span>
                </div>
              </el-col>
              <div class="more">查看更多</div>
            </el-row>
          </el-col>
          <el-col :md="7" :lg="7" :xl="7" class="hidden-sm-and-down">
            <div class="news-right">
              <h3>新闻资讯</h3>
              <p>企业动态</p>
              <p>行业资讯</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!--    footer-->
    <gw-footer></gw-footer>
  </div>
</template>

<script>
import gwHeader from '../../components/header.vue';
import gwFooter from '../../components/footer.vue';
export default {
  name: "NewsList",
  components: { gwHeader, gwFooter },
  data() {
    return {
      newsList: [
        {
          title: "与我同行，谁“羽”争锋——首届“冠通杯”羽毛球赛",
          imgUrl: "news5.png?v=2",
          datetime: "2021/8/16",
        },
        {
          title: "国内最大的医疗器械展一—CMEF中国国际医疗器械博览会",
          imgUrl: "news10.png",
          datetime: "2021/6/20",
        },
        {
          title: "恭喜《销帮主医疗器械经营管理软件》通过上海医疗器械行业协会软件评审！",
          imgUrl: "news6.png?v=2",
          datetime: "2021/5/08",
        },
        {
          title: "陪伴是最长情的告白|科华恒盛续航第120届广交会",
          imgUrl: "news11.png",
          datetime: "2021/3/26",
        },
        {
          title: "打造信息化精准管理平台",
          imgUrl: "news7.png?v=2",
          datetime: "2021/1/10",
        },
        {
          title: "IT系统开发",
          imgUrl: "news8.png?v=2",
          datetime: "2020/8/16",
        },
        {
          title: "为企业提供程管理、实时控制、过程掌控的有效企业运营管理工具",
          imgUrl: "news9.png?v=2",
          datetime: "2021/1/8",
        },
      ],
    }
  },
  methods: {

  },
  mounted(){
    //  初始化动画效果库
    new this.$wow.WOW({
      live: true
    }).init();
  },
}
</script>

<style lang="scss" scoped>
.news-banner{
  width: 100%;
  height: 80rem;
  overflow: hidden;
  position: relative;
  img{
    object-fit: cover;
    width: 100%;
    display: block;
    height: 100%;
    margin: 0 auto;
  }
  .news-banner-text{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    div{
      margin-top: 10rem;
      h2{
        font-size: 3.6rem;
        font-weight: 400;
        color: $white-color;
      }
      p{
        font-size: 2.4rem;
        font-weight: 400;
        color: $white-color;
      }
    }
  }
}
.news{
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 4rem 0;
  background-color: $grey-color;
}
.news-left{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  .news-left-item{
    margin-bottom: 4rem;
    cursor: pointer;
    .news-left-item-image{
      width: 100%;
      height: auto;
      overflow: hidden;
      img{
        display: block;
        width: 100%;
        height: auto;
        transition: 0.5s;
      }
    }
    .news-left-item-image:hover>img{
      transform: scale(1.1);
    }
    .news-left-item-text{
      h5{
        font-size: 1.8rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #0881CA;
        line-height: 3rem;
        height: 6rem;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      span{
        padding: 0.3rem 1rem;
        font-size: 1.6rem;
        font-weight: 500;
        color: $white-color;
        background: #CCCCCC;
        border-radius: 0.4rem;
      }
    }
  }
}
.news-right{
  h3{
    font-size: 1.8rem;
    font-weight: 500;
    color: $black-color;
    margin-bottom: 2rem;
  }
  p{
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 400;
    color: #0881CA;
    line-height: 3rem;
  }
}
.more{
  width: 25rem;
  height: 6.1rem;
  background: $white-color;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2.6rem;
  font-weight: 500;
  color: $text-color;
  margin: 4rem auto;
  border-radius: 0.4rem;
  cursor: pointer;
}
.news-left-item:first-of-type{
  h5{
    height: 3rem !important;
  }
}

</style>
