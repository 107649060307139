<template>
  <div class="wrap">
    <!-- 头部组件 -->
    <gw-header ref="gwheader" page="solution"></gw-header>
    <!-- banner图 -->
    <div class="banner">
      <img :src="util.getImageUrl(details.banner)" class="banner-img">
    </div>
    <!-- 标题 -->
    <div class="title">
      <h1>{{ details.topTitle }}</h1>
      <p>{{ details.topSubTitle }}</p>
    </div>
    <!-- 轮播图 -->
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div v-for="(item, index) in details.swiperImgList" :key="index" class="swiper-slide">
          <img :src="util.getImageUrl(item.imgUrl)" class="swiper-lazy">
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <!-- 解决方法列举 -->
    <div class="solution-list">

      <div class="container solution-list-item" v-for="(item, index) in details.itemList" :key="index">

        <el-row :gutter="40" v-if="item.imgPosition === 'left'">
          <el-col :lg="12" :md="12" :sm="24" :xl="12" :xs="24">
            <div class="solution-item-img">
              <img :src="util.getImageUrl(item.imgUrl)">
            </div>
          </el-col>
          <el-col :lg="12" :md="12" :sm="24" :xl="12" :xs="24">
            <div class="solution-item-detail">
              <div class="detail-text">
                <h1>{{item.title}}</h1>
                <p v-html="item.text"></p>
              </div>
              <div class="detail-img" v-if="item.qrcode != null">
                <img :src="util.getImageUrl(item.qrcode)">
                <div>{{item.tips}}</div>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="40" v-else-if="item.imgPosition === 'right'">
          <el-col :lg="12" :md="12" :sm="24" :xl="12" :xs="24">
            <div class="solution-item-detail" style="margin-left: 0">
              <div class="detail-text">
                <h1>{{item.title}}</h1>
                <p v-html="item.text"></p>
              </div>
              <div class="detail-img" v-if="item.qrcode != null">
                <img :src="util.getImageUrl(item.qrcode)">
                <div>{{item.tips}}</div>
              </div>
            </div>
          </el-col>
          <el-col :lg="12" :md="12" :sm="24" :xl="12" :xs="24">
            <div class="solution-item-img">
              <img :src="util.getImageUrl(item.imgUrl)">
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 宣传图部分 -->
    <div class="advertising">
      <img :src="util.getImageUrl(details.smallLogo)" class="advertising-logo">
      <div class="advertising-text">{{details.logoFooterTitle}}</div>
      <div class="container advertising-pic">
        <img :src="util.getImageUrl(details.picImgUrl)">
      </div>
    </div>
    <!-- 底部信息部分 -->
    <div class="footer_bottom">
      <h1>{{details.bottomTitle}}</h1>
      <p>{{details.bottomSubTitle}}</p>
      <img :src="util.getImageUrl(details.bigLogo)">
      <ul>
        <li v-for="(item, index) in details.tagList" :key="index">{{item.title}}</li>
      </ul>
      <div class="footer-button">
        <div class="footer-button-try">申请试用</div>
        <div class="footer-button-consult">立即咨询</div>
      </div>
      <div class="footer-note">{{details.footerTips}}</div>
    </div>
    <!-- 底部组件 -->
    <gw-footer></gw-footer>
  </div>
</template>

<script>
import gwHeader from '../../components/header.vue'
import gwFooter from '../../components/footer.vue'
import Swiper from 'swiper'

export default {
  name: 'solutionIndex',
  components: {gwHeader, gwFooter},
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  data() {
    return {
      msg: "我是解决方案页面",
      // banner信息
      bannerInfo: {imgUrl: "momwow_banner.png", title: "母婴特许经营加盟解决方案", subTitle: "母婴家庭智慧零售连锁平台"},
      // 轮播图图片
      swiperList: ["momwow_swipe_one.png", "momwow_swipe_two.png", "momwow_swipe_three.png"],
      details: {},  //页面内容
    }
  },
  watch: {
    '$route' (to, from) {
      // 对路由变化作出响应...
      console.log(123);
    }
  },

  created() {
    if(JSON.stringify(this.$route.params) == "{}"){  //如果是空对象就去本地存储查找页面数据
     let localDetails = localStorage.getItem('details');
     if(localDetails){
       this.details = JSON.parse(localDetails);
     }else{
       //默认第一个解决方案
       this.details = {
           banner: "xbz_banner.png",
           topTitle: "销帮主医疗器械经营管理平台",
           topSubTitle: "“规范+便捷”的医疗器械经营企业服务平台",
           swiperImgList: [
             { imgUrl: "xbz_swipe_one.png"},
             { imgUrl: "xbz_swipe_two.png"},
             { imgUrl: "xbz_swipe_three.png"},
           ],
           itemList: [
             { imgPosition: "left", imgUrl: "xbz_solution_one.png", title: "进销存管理", text: "1.对货、单、款的连动全面管理<br>\n" +
                   "2.对生产企业、供货企业、购货企业以及本  企业的经营资\n" +
                   "   格及经营范围进行全面管理<br>\n" +
                   "3.有效预警管理，提前报警，超限禁用<br>\n" +
                   "4.对货品的从采购到库存再到销售进行全链条单据管理并留  \n" +
                   "   有有效操作痕迹", qrcode: null, tips: "微信扫码体验小程序"},
             { imgPosition: "right", imgUrl: "xbz_solution_two.png", title: "数据云对接", text: "1.  监管平台轻松对接<br>\n" +
                   "2.  数据信息实时管控<br>\n" +
                   "3.  异地化多点办公<br>", qrcode: null, tips: ""},
             { imgPosition: "left", imgUrl: "xbz_solution_three.png", title: "系统设计依据", text: "1.《医疗器械经营监督管理办法》（国家食品药品监督管理\n" +
                   "   总局令第8号）<br>\n" +
                   "2.《医疗器械监督管理条例》（国务院令第650号）<br>\n" +
                   "3.医疗器械监管管理条例-2017年修订版 沪（2015）785号 \n" +
                   "   《上海市医疗器械经营质量管理规范实施细则》<br>\n" +
                   "4.沪[2015]786号《上海市医疗器械经营质量管理规范实施\n" +
                   "   细则评定内容及检查要点》<br>", qrcode: null, tips: ""},
           ],
           smallLogo: "xbz_advertising_logo.png",
           logoFooterTitle: "“规范+便捷”的医疗器械经营企业服务平台",
           picImgUrl: "xbz_advertising_pic.png",
           bottomTitle: "销帮主医疗器械经营管理平台",
           bottomSubTitle: "应有尽有",
           bigLogo: "xbz_footer_logo.png",
           tagList:[
             { title: "节约成本"},
             { title: "使用便捷"},
             { title: "功能共享"},
             { title: "弹性扩展"},
             { title: "云端升级"},
           ],
           footerTips: "由于项目多为个性定制化，实际价格区间以咨询结果为参考",
         }
     }
    }else{  //params不为空对象则说明是跳转页面过来的
      let details = this.$route.params.details;
      this.details = JSON.parse(details);
      //本地存储记录详情数据
      localStorage.setItem('details', this.$route.params.details);
    }
  },
  mounted() {
    //  初始化动画效果库
    new this.$wow.WOW({
      live: true
    }).init();
    new Swiper('.swiper-container', {
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1500,
      allowTouchMove: false,
      lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 3,
      },
      centeredSlides: true,
      spaceBetween: 21,
      slidesOffsetBefore: 40,
      loop: true,
      slidesPerView: 'auto',
      on: {
        slideChangeTransitionEnd: function () {
          this.slides.transition(this.params.autoplay.delay + this.params.speed).transform('translate3d(-60px, 0, 0)');
        },
        slideChangeTransitionStart: function () {
          this.slides.transition(this.params.speed).transform('translate3d(0, 0, 0)');
        },
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<div class="' + className + '"><span></span><i></i></div>';
        },
      }
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.wrap {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.banner {
  height: 80rem;
  width: 100%;

  .banner-img {
    object-fit: cover;
    width: 100%;
    display: block;
    height: 100%;
    margin: 0 auto;
  }
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 13.8rem;
  padding-bottom: 8.6rem;

  h1 {
    font-size: 3.6rem;
    color: #333333;
    font-weight: bold;
  }

  p {
    margin-top: 3.6rem;
    font-size: 2.4rem;
    color: #333333;
  }
}

.swiper-slide {
  width: 978px;
  transition-timing-function: linear;
}

@media only screen and (max-width: 1200px) {
  .swiper-slide {
    width: 770px;
  }
}

@media only screen and (max-width: 980px) {
  .swiper-slide {
    width: 471px;
  }
}

@media only screen and (max-height: 480px) {
  .swiper-slide {
    width: 471px;
  }
}

.swiper-slide img {
  width: 100%;
  border-radius: 4px;
}

.swiper-slide .title {
  position: absolute;
  transform: rotate(90deg);
  transform-origin: left top;
  left: -3px;
  font-size: 11px;
  color: rgb(102, 102, 102);
}

.swiper-pagination-bullet {
  background: none;
  opacity: 1;
  margin: 0 6px !important;
  width: 9px;
  height: 9px;
  position: relative;
  outline: none;
  vertical-align: middle;
}

.swiper-pagination-bullet span {
  width: 3px;
  height: 3px;
  background: #CCC;
  display: block;
  border-radius: 50%;
  margin-top: 3px;
  margin-left: 3px;
}

.swiper-pagination-bullet i {
  background: #000;
  height: 1px;
  width: 20px;
  position: absolute;
  top: 4px;
  transform: scaleX(0);
  transform-origin: left;
  z-index: 3;
  transition-timing-function: linear;
}

.swiper-pagination-bullet-active span, .swiper-pagination-bullet:hover span {
  width: 9px;
  height: 9px;
  margin-top: 0;
  margin-left: 0;
  background: #000;
  position: relative;
  z-index: 1;
}

.swiper-pagination-bullet-active i {
  animation: middle 6s;
}

.swiper-pagination-bullet:first-child.swiper-pagination-bullet-active i {
  animation: first 6s;
}

.swiper-pagination-bullet:last-child.swiper-pagination-bullet-active i {
  animation: last 6s;
}

@keyframes first {
  0% {
    transform: scaleX(0.5);
    left: 0px;
  }
  /*091*/
  100% {
    transform: scaleX(1);
    left: 2px;
  }
  /*0915*/
}

@keyframes last {
  0% {
    transform: scaleX(0.7);
    left: -10px;
  }
  /*1090*/
  20% {
    transform: scaleX(0.3);
    left: 2px;
  }
  /*090*/
  100% {
    transform: scaleX(0.3);
    left: 0px;
  }
  /*090*/
}

@keyframes middle {
  0% {
    transform: scaleX(0.7);
    left: -10px;
  }
  /*1091*/
  20% {
    transform: scaleX(0.45);
    left: 2px;
  }
  /*092*/
  100% {
    transform: scaleX(1);
    left: 2px;
  }
  /*0913*/
}

.solution-list {
  margin-top: 13.2rem;
  width: 100%;

  .solution-list-item {
    padding-top: 11.3rem;
    padding-bottom: 11.3rem;

    .solution-item-img {
      // padding-left: 2.1rem;
      // width: 51.3rem;
      width: 100%;

      img {
        // width: 51.3rem
        width: 100%;
        display: block;
        height: auto;
      }
    }

    .solution-item-detail {
      margin-left: 4.2rem;
      padding-left: 2.8rem;
      padding-top: 8rem;

      .detail-text {
        margin-bottom: 4.7rem;

        h1 {
          font-size: 3.6rem;
          font-weight: bold;
        }

        p {
          margin-top: 3.7rem;
          // width: 48rem;
          font-size: 1.8rem;
          line-height: 3rem;
        }
      }

      .detail-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20.6rem;

        div {
          font-size: 1.6rem;
        }
      }
    }
  }
}

.advertising {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5.9rem;
  padding-bottom: 8.6rem;
  width: 100%;
  background-color: #262626;

  .advertising-logo {
    display: block;
    //width: 22.1rem;
  }

  .advertising-text {
    margin: 4rem 0 3rem;
    font-size: 2rem;
    color: #ffffff;
  }

  .advertising-pic {
    img {
      width: 100%;
    }
  }
}

.footer_bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 17.4rem 0 12.8rem;

  h1 {
    font-size: 3.6rem;
    font-weight: bold;
  }

  p {
    margin: 2.1rem 0 9.6rem;
    font-size: 2.4rem;
    color: #666666;
  }

  img {
    display: block;
    margin-bottom: 6.6rem;
    //width: 24.1rem;
  }

  ul {
    overflow: hidden;
    width: 35rem;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    li {
      margin-right: 0.9rem;
      margin-bottom: 0.7rem;
      padding: 0.7rem 1.2rem;
      font-size: 1.4rem;
      border: 0.1rem solid $black-color;
      border-radius: 0.5rem;
    }

    li:last-child {
      margin-right: 0;
    }
  }

  .footer-button {
    display: flex;
    margin: 6.9rem 0 2.4rem;

    .footer-button-try {
      margin-right: 1.6rem;
      padding: 1.5rem 1.6rem;
      font-size: 1.8rem;
      font-weight: bold;
      border: 0.1rem solid $black-color;
      border-radius: 0.5rem;
    }

    .footer-button-consult {
      padding: 1.5rem 1.6rem;
      font-size: 1.8rem;
      font-weight: bold;
      background-color: $black-color;
      border-radius: 0.5rem;
      color: #ffffff;
    }
  }

  .footer-note {
    font-size: 1.4rem;
    color: $text-color;
  }
}
</style>
