<template>
  <div class="wrap">
    <!--    header-->
    <gw-header page="recruit" ref="gwheader"></gw-header>
<!--    招聘内容-->
    <div class="recruit">
      <div class="container">
        <el-row :gutter="80">
          <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20">
            <div class="details-left">
              <div class="details-title">
                <h1>{{details.title}}</h1>
                <p>经验：{{details.exp}}丨学历：{{details.education}}丨{{details.datetime}}</p>
              </div>
              <div class="details-content">
                <div class="rich-text" v-html="details.content"></div>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <div class="details-right">
              <span>申请职位</span>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="container">
        <el-row :gutter="80">
          <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20">
            <h2 class="recruit-title">相关职位推荐</h2>
            <el-row class="news-left">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="news-left-item" v-for="(item, index) in recruitList" :key="index">
                <div class="recruit-left-item" @click="util.jumpPage('recruitDetail', {name:123})">
                  <h3>{{item.title}}</h3>
                  <p>经验：{{item.exp}}丨学历：{{item.education}}</p>
                  <div v-html="item.content"></div>
                  <div>
                    <span>申请职位</span>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4"></el-col>
        </el-row>
      </div>
    </div>
    <!--    footer-->
    <gw-footer></gw-footer>
  </div>
</template>

<script>
import gwHeader from '../../components/header.vue';
import gwFooter from '../../components/footer.vue';
export default {
  name: "NewsDetails",
  components: { gwHeader, gwFooter },
  data() {
    return {
      details: {
        datetime: "2021年8月26日",
        title: "java开发工程师（上海）",
        exp: "3-5年 ",
        education: "本科",
        content: "<h3 style='font-size: 24px; color: #333333'>职位描述：</h3><p style='font-size: 18px; color: #666666; line-height:36px'>1、负责公司各项业务的后台开发；<br>\n" +
            "2、能够保质保量的完成工作分配任务，尊循编码规范；<br>\n" +
            "3、负责核心业务的骨干代码编写并对业务层提供基础支撑；<br>\n" +
            "4、理解复杂的业务逻辑，对业务模型进行设计，编写出规范的设计文档；<br>\n" +
            "5、有很强的框架和数据库设计功底，能够将复杂的业务逻辑进行合理拆解，有模块化研发基础，有微服务编程意识；<br>\n" +
            "6、负责小组的任务分配及人员管理、模块代码审查和质量把控。<br>\n</p><br><br><h3 style='font-size: 24px; color: #333333'>任职要求：</h3><p style='font-size: 18px; color: #666666; line-height:32px'>1、本科及以上学历，计算机相关专业；3~5年以上后端服务系统相关实战经验；\n<br>" +
            "2、熟练使用RPC框架，具备相关的分布式开发经验，有企业大数据服务的互联网从业经历者优先；<br>\n" +
            "3、拥有扎实的编程能力，熟练掌握常见数据结构，掌握Java常用框架和开发模式；<br>\n" +
            "4、熟悉Springcloud、MyBatis、Dubbo等开源框架，了解其工作原理；熟悉微服务开发；<br>\n" +
            "5、熟练掌握常见SQL、NoSQL数据库原理、数据库设计、查询编写和优化；<br>\n" +
            "6、有SaaS服务开发经验者优先、熟悉门店管理、微信商城等业务优先；<br>\n" +
            "7、逻辑分析能力强，善于沟通，有良好的团队合作精神及团队管理能力，良好的学习能力。</p>",
      },
      recruitList: [
        {
          title: "JAVA开发工程师 ",
          exp: "3-5年 ",
          education: "本科",
          content: "1、负责公司各项业务的后台开发；<br>\n" +
              "2、能够保质保量的完成工作分配任务，尊循编码规范；<br>\n" +
              "3、负责核心业务的骨干代码编写并对业务层提供基础支撑…<br>\n",
        },
        {
          title: "Android开发工程师 ",
          exp: "3-5年 ",
          education: "本科",
          content: "1、三年以上android产品研发经验<br>\n" +
              "2、精通Java语言、Android平台开发基础；<br>3.熟悉TCP、HTTP等网络协议，熟练使用数据库…\n",
        },
      ]
    }
  },
  methods: {

  },
  mounted(){
    //  初始化动画效果库
    new this.$wow.WOW({
      live: true
    }).init();
  },
}
</script>

<style lang="scss" scoped>
.recruit{
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #F5F5F5;
}
.details-left{
  .details-title{
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    overflow: hidden;
    margin-bottom: 3rem;
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid #CCCCCC;
    h1{
      font-size: 3.2rem;
      font-weight: 500;
      line-height: 5rem;
      color: $black-color;
      display: block;
      width: 100%;
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap;
    }
    p{
      font-size: 1.6rem;
      font-weight: 500;
      color: $black-color;
      margin-top: 1rem;
    }
  }
  .details-content{
    width: 100%;
    height: auto;
    overflow: hidden;
    h2{
      display: block;
      width: 40%;
      margin: 3rem auto;
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-align: center;
      line-height: 4rem;
      font-size: 2rem;
      color: $black-color;
    }
    .rich-text{
      width: 100%;
      height: auto;
      overflow: hidden;
      margin-bottom: 5rem;
    }
  }
}

.details-right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  margin-top: 3rem;
  span{
    display: block;
    text-align: center;
    line-height: 3.8rem;
    width: 11.6rem;
    height: 3.8rem;
    background: linear-gradient(0deg, #057FC8, #3FB5FE);
    border-radius: 0.6rem;
    font-size: 2rem;
    font-weight: 500;
    color: $white-color;
    cursor: pointer;
  }
}
.recruit-title{
  font-size: 2.4rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: $black-color;
}
.recruit-left-item{
  width: 100%;
  height: auto;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  background-color: $white-color;
  padding: 2rem;
  transition: 0.5s;
  margin: 2rem 0;
  h3{
    font-size: 2rem;
    margin: 0 !important;
    font-weight: bold;
    color: $black-color;
    line-height: 4rem;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    transition: 0.5s;
  }
  p{
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 4rem;
    color: $footer-color;
  }
  div:first-of-type{
    width: 100%;
    font-size: 1.8rem;
    line-height: 3rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: $text-color;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    height: 9rem;
  }
  div:last-of-type{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 3rem 0 2rem;
    span{
      width: 10.8rem;
      height: 3.4rem;
      background: linear-gradient(0deg, #057FC8, #3FB5FE);
      border-radius: 0.6rem;
      display: inline-block;
      text-align: center;
      line-height: 3.4rem;
      font-size: 1.8rem;
      font-weight: 500;
      color: $white-color;
      cursor: pointer;
    }
  }
}
.recruit-left-item:hover{
  box-shadow: 0px 6px 10px 0px rgba(102, 102, 102, 0.2);
  h3{
    color: #0B85CE;
  }
}
.news-left{
  height: auto;
  overflow: hidden;
  margin-bottom: 5rem;
}
</style>
