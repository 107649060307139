<template>
  <div></div>
</template>

<script>
export default {
  name: "refresh",
  beforeRouteEnter(to, from, next) {
    next((xq) => {
      xq.$router.replace({
        name: from.name,
        params: to.params
      })
    })
  }
}
</script>

<style scoped>

</style>
