<template>
  <div class="wrap">
    <!--    header-->
    <gw-header page="news" ref="gwheader"></gw-header>
<!--    导航条-->
    <div class="nav-row">
      <div class="container">
        <div class="nav-row-wrap">
          <h5 v-for="(item, index) in navRowList" :key="index">{{item.title}}</h5>
        </div>
      </div>
    </div>
<!--    新闻内容-->
    <div class="container">
      <el-row :gutter="80">
        <el-col :xs="24" :sm="24" :md="21" :lg="21" :xl="21">
          <div class="details-left">
            <div class="details-title">
              <h1>{{details.title}}</h1>
              <p>{{details.datetime}}</p>
            </div>
            <div class="details-content">
              <h2>{{details.title}}</h2>
              <div class="rich-text" v-html="details.content"></div>
            </div>
          </div>
        </el-col>
        <el-col class="hidden-sm-and-down" :md="3" :lg="3" :xl="3">
          <div class="details-right">
            <h3>分享</h3>
            <img @click="copyUrl" :src="util.getIconUrl('wechat_icon.png')" />
            <img @click="copyUrl" :src="util.getIconUrl('weibo_icon.png')" />
            <img @click="copyUrl" :src="util.getIconUrl('qq_icon.png')" />
          </div>
        </el-col>

      </el-row>
    </div>
    <!--    footer-->
    <gw-footer></gw-footer>
  </div>
</template>

<script>
import gwHeader from '../../components/header.vue';
import gwFooter from '../../components/footer.vue';
export default {
  name: "NewsDetails",
  components: { gwHeader, gwFooter },
  data() {
    return {
      navRowList: [
        { title: "新闻媒体"},
        { title: "新闻资讯"},
        { title: "文章详情"},
      ],
      details: {
        datetime: "2021.7.29",
        title: "国内最大的医疗器械展 —CMEF中国国际医疗器械博览会",
        content: "<p style='font-size:16px; color:#666666'>2018年4月11日，第79届中国国际医疗器械（春季）博览会、第26届中国国际医疗器械设计与制造技术（春季）展览会在国家会展中心（上海）拉开了帷幕。<br /><br />该盛会即可变成申城乃至全国专业人员、医疗机构、医疗器械行业等聚焦的对象。主办方国药励展围绕新时代健康中国主旋律，以“医疗数字化时代”为主题，融合CMEF+的理念，携手联影、迈瑞、GE、PHILIPS、SIEMENS、鱼跃、万东、东软、威高、新华、康达、开立、理邦、奥林巴斯、罗氏、赛默飞、国药器械、BD、复星诊断、国药控股、艾默生、岛津、蓝帆医疗等4100余家国内外展商及近千名全球顶尖专家学者，为CMEF观众奉上了一场前所未有的产学研用深度融和的智慧医疗盛宴。<br /><br /></p><img style='width:100%' src='https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/gateonWeb/image/news_detail1.png' /><p style='font-size:16px; color:#666666'><br /><br />为了满足用户信息化需求，建设云端产品平台，提升医疗器械行业经营管理水平，冠通新创科技（上海）有限公司的销帮主医疗器械经营管理平台也参加了本次展会，并通过展会结识了许多业内中、大型医疗器械行业相关公司和生产厂家，并同时与他们确立了合作关系，使销帮主的市场渠道不断扩大，并使平台的客户资源覆盖不断向全国范围扩展。\n" +
            "\n" +
            "<br /><br />销帮主是一款功能全面、逻辑严密、操作便捷的医疗器械经营管理系统，经过细致的调研和长期研发，产品符合国家医疗器械法律法规的相关要求，满足医疗器械经营企业日常的进销存业务需求，目前已有近2000家企业部署了销帮主系统。同时随着国家政策、法律法规的颁布、更新，以及入驻企业的用户体验报告、反馈意见，我们对销帮主进行着不断的升级改造、版本更新，因而销帮主在产品成熟程度以及操作管理等方面遥遥领先于行业内其他同类型产品。我司始终秉承着帮助企业以最小的投入实现最大的价值的理念进行产品研发以及技术服务，旨在为客户提供最优质的产品软件以及培训服务，同时吸纳广大客户提出的建议及需求，为客户提供最优秀的产品！积极响应打造“上海服务“，让客户享受最到位的服务！<br /><br /></p><img style='width:100%' src='https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/gateonWeb/image/news_detail2.png' />",
      }
    }
  },
  methods: {
    //复制当前链接至剪切板
    copyUrl () {
      const inputTag = document.createElement('input');
      document.body.appendChild(inputTag);
      inputTag.setAttribute('value', "http://localhost:8080/#/newsDetail");
      inputTag.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$message({
          type: 'success',
          message: '链接已复制到剪切板',
        });
      }
      document.body.removeChild(inputTag);
    },
  },
  mounted(){
    //  初始化动画效果库
    new this.$wow.WOW({
      live: true
    }).init();
  },
}
</script>

<style lang="scss" scoped>
.nav-row{
  width: 100%;
  height: 4.8rem;
  display: flex;
  align-items: center;
  background: #F2F2F2;
}
.nav-row-wrap{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  h5{
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
    color: $black-color;
  }
  h5:after{
    content: ">";
    display: inline-block;
    font-size: 1.6rem;
    font-weight: 500;
    margin: 0 1.5rem;
    color: $black-color;
  }
  h5:last-of-type:after{
    content: "";
  }

}
.details-left{
  .details-title{
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    overflow: hidden;
    border-bottom: 0.1rem solid #F2F2F2;
    h1{
      font-size: 3.2rem;
      font-weight: 500;
      line-height: 5rem;
      color: $black-color;
      display: block;
      width: 100%;
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap;
    }
    p{
      font-size: 2rem;
      font-weight: 500;
      color: $black-color;
      margin-top: 1rem;
    }
  }
  .details-content{
    width: 100%;
    height: auto;
    overflow: hidden;
    h2{
      display: block;
      width: 40%;
      margin: 3rem auto;
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-align: center;
      line-height: 3rem;
      font-size: 2.2rem;
      color: $black-color;
    }
    .rich-text{
      width: 100%;
      height: auto;
      overflow: hidden;
      margin-bottom: 5rem;
    }
  }
}

.details-right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 18rem;
  h3{
    font-size: 1.8rem;
    font-weight: 500;
    color: $text-color;
  }
  img{
    display: block;
    width: 3.8rem;
    height: 3.8rem;
    margin: 1rem 0;
    cursor: pointer;
  }
}
</style>
