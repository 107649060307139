<template>
  <div class="wrap">
<!--    header-->
    <gw-header page="home" ref="gwheader"></gw-header>
<!--    banner-->
    <div class="banner">
      <div class="banner-item" v-for="(item, index) in bannerList">
        <img class="banner-img" :src="util.getImageUrl(item.imgUrl)" />
        <div class="banner-content">
          <h1 v-if="item.title" style="font-family: PingFang SC !important;font-weight: 400 !important;">{{item.title}}</h1>
          <p v-if="item.subTitle" style="font-family: PingFang SC !important;font-weight: 400 !important;">{{item.subTitle}}</p>
          <div v-if="item.btnText">
              <span>{{item.btnText}}</span>
              <img :src="util.getIconUrl('banner_right_icon.png')" />
          </div>
        </div>
      </div> 
    </div>
<!--   banner footer-->
    <div class="banner-footer">
      <div class="container">
        <el-row>
          <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6" class="banner-footer-item" v-for="(item, index) in bannerFooterList" :key="index">
            <div class="item-big">
              <h1>{{item.bigTxt}}</h1>
              <span>{{item.smallTxt}}</span>
            </div>
            <div class="item-text">
              <p>{{item.text1}}</p>
              <p>{{item.text2}}</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
<!--    我们的服务领域-->
    <div class="service">
      <div class="title">
        <div>
          <h1 class="wow slideInLeft" data-wow-duration="1s" data-wow-offset="10"  data-wow-iteration="1">我们的服务领域</h1>
          <img :src="util.getImageUrl('gt_back_logo.png')" class="wow slideInRight" data-wow-duration="1s" data-wow-offset="10"  data-wow-iteration="1" />
        </div>
        <p class="wow bounceInUp" data-wow-duration="1s" data-wow-offset="10"  data-wow-iteration="1">追求卓越，为每个客户创造价值是我们矢志不渝的奋斗目标</p>
      </div>
      <div class="container service-wrap">
        <el-row style="height: 100%">
          <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16" class="service-left">
            <div class="service-item wow slideInLeft" v-for="(item, index) in serviceList" :key="index" v-if="index < 4" data-wow-duration="1s" data-wow-offset="10"  data-wow-iteration="1">
              <img :src="util.getImageUrl(item.backImgUrl)" />
              <div class="service-item-wrap">
                <div class="service-item-title">
                  <img :src="util.getIconUrl(item.iconImgUrl)" />
                  <h1>{{item.title}}</h1>
                </div>
                <div class="service-item-tag">
                  <span v-for="(subItem, subIndex) in item.tagList" :key="subIndex">{{subItem.title}}</span>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="service-right">
            <div class="service-item wow slideInRight" v-for="(item, index) in serviceList" :key="index" v-if="index >= 4" data-wow-duration="1s" data-wow-offset="10"  data-wow-iteration="1">
              <img :src="util.getImageUrl(item.backImgUrl)" />
              <div class="service-item-wrap">
                <div class="service-item-title">
                  <img :src="util.getIconUrl(item.iconImgUrl)" />
                  <h1>{{item.title}}</h1>
                </div>
                <div class="service-item-tag">
                  <span v-for="(subItem, subIndex) in item.tagList" :key="subIndex">{{subItem.title}}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

      </div>
    </div>
<!--    客户案例-->
    <div class="case">
      <div class="container">
        <div class="title">
          <div>
            <h1>客户案例</h1>
          </div>
        </div>
        <el-row class="flex-row">
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8" class="case-item" v-for="(item,index) in caseList" :key="index">
            <div class="case-item-wrap">
              <div class="case-item-img">
                <img :src="util.getImageUrl(item.imgUrl)" />
              </div>
              <div class="case-item-content">
                <img :src="util.getImageUrl(item.logoImgUrl)" />
                <h3>{{item.title}}</h3>
                <p>{{item.subTitle}}</p>
                <div class="case-item-tag">
                  <span v-for="(tagItem, tagIndex) in item.tagList" :key="tagIndex">{{tagItem.title}}</span>
                </div>
              </div>
            </div>

          </el-col>
        </el-row>
        <div class="more-case" @click="util.jumpPage('caseIndex')">
          <span>更多案例</span>
        </div>
      </div>
    </div>
<!--    合作客户-->
    <div class="customer">
      <div class="container customer-containe">
        <div class="title" style="margin-bottom: 2rem">
          <div>
            <h1 style="color: #FFFFFF">合作客户</h1>
          </div>
          <p style="color: #FFFEFE">与强者同行，我们一定更强，在这里，我们希望与您同行</p>
        </div>
        <el-row class="flex-row">
          <el-col :xs="8" :sm="8" :md="6" :lg="4" :xl="4" class="customer-item" v-for="(item, index) in customerList" :key="index">
            <img :src="util.getImageUrl(item.imgUrl)" />
          </el-col>
        </el-row>
      </div>
    </div>
<!--    新闻资讯-->
    <div class="news">
      <div class="container">
        <div class="title">
          <div>
            <h1>新闻资讯</h1>
            <img :src="util.getImageUrl('gt_back_logo.png')" />
          </div>
          <p style="margin-left: -6.5rem">热门资讯 率先掌握</p>
        </div>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10" class="news-left">
            <div class="news-item" v-for="(item, index) in newsList" :key="index" v-if="index < 1" @click="util.jumpPage('newsDetail')">
              <div class="news-item-img">
                <img :src="util.getImageUrl(item.imgUrl)" />
              </div>
              <div class="news-item-content">
                <h3>
                  <span v-if="item.new">NEW</span>
                  {{item.title}}
                </h3>
                <p>{{item.text}}</p>
                <p>{{item.datetime}}</p>
              </div>
            </div>

          </el-col>
          <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14" class="col-lg-7 col-md-7 col-sm-12 col-xs-12 news-right">
            <div class="news-right-item" v-for="(item, index) in newsList" :key="index" v-if="index >= 1" @click="util.jumpPage('newsDetail')">
              <div class="new-right-item-img">
                <img :src="util.getImageUrl(item.imgUrl)" />
              </div>
              <div class="new-right-item-content">
                <div class="news-right-item-content-title">
                  <h3>{{item.title}}</h3>
                  <p>{{item.datetime}}</p>
                </div>
                <p>{{item.text}}</p>
              </div>
            </div>
          </el-col>

        </el-row>
      </div>
    </div>
<!--    footer-->
    <gw-footer></gw-footer>
  </div>
</template>

<script>
import gwHeader from '../../components/header.vue';
import gwFooter from '../../components/footer.vue';
export default {
  name: 'Home',
  components: { gwHeader, gwFooter },
  data() {
    return {
      msg: "我是首页",
      bannerList: [  //banner
        { imgUrl : "banner.png", title: "生态互联  创想未来", subTitle: "- 用数据为企业成长和产业发展护航 -", btnText: "了解更多"}
      ],
      bannerFooterList: [  //banner底部列表
        { bigTxt: "5", smallTxt: "年", text1: "5年专注于科技工具化", text2: "因为专注，所以专业"},
        { bigTxt: "800", smallTxt: "家", text1: "服务客户超过800家", text2: "遍及各大行业"},
        { bigTxt: "100", smallTxt: "+", text1: "100+核心技术认证", text2: "用先进的技术更好的助力项目"},
        { bigTxt: "100", smallTxt: "%", text1: "100%客户满意度", text2: "良好的口碑是我们立足的根本"},
      ],
      serviceList: [  //服务列表
        {
          title: "在线工具管理",
          backImgUrl: "service1.png",
          iconImgUrl: "service_icon1.png",
          tagList: [
            { title: "商品管理"},
            { title: "会员管理"},
            { title: "员工管理"},
            { title: "活动管理"},
            { title: "进销存"},
          ]
        },
        {
          title: "人机互联",
          backImgUrl: "service2.png",
          iconImgUrl: "service_icon2.png",
          tagList: [
            { title: "店铺硬件互联"},
            { title: "商品收银一体机"},
            { title: "快速盘点"},
            { title: "员工客户线上管理"},
          ]
        },
        {
          title: "小程序",
          backImgUrl: "service4.png",
          iconImgUrl: "service_icon4.png",
          tagList: [
            { title: "商城类"},
            { title: "工具类"},
            { title: "社群类"},
            { title: "营销类"},
          ]
        },
        {
          title: "互联网生态搭建",
          backImgUrl: "service5.png",
          iconImgUrl: "service_icon5.png",
          tagList: [
            { title: "线下店铺管理"},
            { title: "线上商城搭建"},
            { title: "员工管理系统"},
            { title: "商品管理系统"},
            { title: "活动营销系统"},
          ]
        },
        {
          title: "App开发",
          backImgUrl: "service3.png",
          iconImgUrl: "service_icon3.png",
          tagList: [
            { title: "商城类"},
            { title: "工具类"},
            { title: "社群类"},
            { title: "营销类"},
          ]
        },
        {
          title: "个性化平台定制",
          backImgUrl: "service6.png",
          iconImgUrl: "service_icon6.png",
          tagList: [
            { title: "营销系统"},
            { title: "加盟体系"},
            { title: "特许经营"},
            { title: "员工管理"},
          ]
        }
      ],
      caseList: [  //案例列表
        {
          title: "智能云监管平台 ",
          subTitle: "300+上海食品生产企业接入平台 ",
          imgUrl: "case1.png",
          logoImgUrl: "case_logo1.png",
          tagList: [
            { title: "监管"},
            { title: "便利"},
            { title: "300+食品企业接入"},
          ],
        },
        {
          title: "黑金芝麻 ",
          subTitle: "赋能50000家母婴实体门店",
          imgUrl: "case2.png",
          logoImgUrl: "case_logo2.png",
          tagList: [
            { title: "医疗"},
            { title: "便利"},
            { title: "赋能5000+门店"},
          ],
        },
        {
          title: "销帮主医疗器械经营管理软件",
          subTitle: "合作企业超  3000+ ",
          imgUrl: "case3.png",
          logoImgUrl: "case_logo3.png",
          tagList: [
            { title: "医疗"},
            { title: "软件"},
            { title: "合作企业3000+"},
          ],
        },
        {
          title: "食品追溯管理和公众查询平台",
          subTitle: "入驻企业超  7000+",
          imgUrl: "case4.png",
          logoImgUrl: "case_logo4.png",
          tagList: [
            { title: "监管"},
            { title: "便利"},
            { title: "入驻企业7000+"},
          ],
        },
        {
          title: "菌宝家族项目运营 ",
          subTitle: "3星特许经营店主团队100+",
          imgUrl: "case5.png",
          logoImgUrl: "case_logo5.png",
          tagList: [
            { title: "数字化"},
            { title: "便利"},
            { title: "用户人数300000"},
          ],
        },
        {
          title: "萌百格新零售",
          subTitle: "入驻合作门店超1000家 ",
          imgUrl: "case6.png",
          logoImgUrl: "case_logo6.png",
          tagList: [
            { title: "零售"},
            { title: "便利"},
            { title: "会员人数超30万"},
          ],
        },
      ],
      customerList: [  //合作客户列表
        { imgUrl: "customer1.png"},
        { imgUrl: "customer2.png"},
        { imgUrl: "customer3.png"},
        { imgUrl: "customer4.png"},
        { imgUrl: "customer5.png"},
        { imgUrl: "customer6.png"},
        { imgUrl: "customer7.png"},
        { imgUrl: "customer8.png"},
        { imgUrl: "customer9.png"},
        { imgUrl: "customer10.png"},
        { imgUrl: "customer11.png"},
        { imgUrl: "customer12.png"},
        { imgUrl: "customer13.png"},
        { imgUrl: "customer14.png"},
        { imgUrl: "customer15.png"},
        { imgUrl: "customer16.png"},
        { imgUrl: "customer17.png"},
        { imgUrl: "customer18.png"},
      ],
      newsList: [  //新闻资讯列表
        {
          title: "与我同行，谁“羽”争锋—记首届“冠通杯”羽毛",
          text: "各组队员迅速进入状态，使劲浑身解数，展开了激烈的比拼。高潮迭 起、精彩纷呈，只见选手们时而下蹲飞身救球各组队员迅速进入状态，使劲浑身解数，展开了激烈的比拼。高潮迭 起、精彩纷呈，只见选手们时而下蹲飞身救球",
          datetime: "11-13",
          new: true,
          imgUrl: "news1.png"
        },
        {
          title: "国内最大的医疗器械展一CMEF中国国际医疗器械...",
          text: "2018年411日，第79届中国国际医疗器械（春季）博览会、第26届中国国际医疗器械设计与制造技术（春季）展览会在国家会展中心（上海）拉开了帷幕。该盛会即可变成申城乃至全国专业人员、医疗机构、医疗器械行业等聚焦的...",
          datetime: "06-29",
          new: false,
          imgUrl: "news2.png"
        },
        {
          title: "《销帮主医疗器械经营管理软件》通过上海医疗器...",
          text: "2017年12月，《销帮主医疗器械经营管理软件》顺利通过了上海医疗器械行业协会组织的医疗器械经营企业计算机信息管理系统评审，获得了软件合格证书，成为首批通过上海市医疗器械行业协会审核的软件，为上海地区的平台...",
          datetime: "06-29",
          new: false,
          imgUrl: "news3.png"
        },
        {
          title: "陪伴是最长情的告白 | 科华恒盛续航第120届广交会",
          text: "【环球网报道 记者 李德意】美国总统特朗普于当地时间6月1日在白宫表示，《巴黎协定》让美国处于不利位置，而让其他国家受益。美国将重新开启谈判，寻求达成一份对美国公平的协议。“即日起，美国将停止落实不具有约束力的...",
          datetime: "03-26",
          new: false,
          imgUrl: "news4.png"
        }
      ],
    }
  },
  methods: {
    jumpSolution: function (){
      this.$router.push({path: "/solutionIndex"})
    },
    //监听页面滚动事件
    handleScroll() {
      //获取滚动时的高度
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop < 200) {
        //滚动小于200修改主题为黑色
        this.$refs.gwheader.theme = 'black';  //修改头部主题
      }
      if (scrollTop > 200) {
        //大于200修改主题为白色
        this.$refs.gwheader.theme = 'white';  //修改头部主题
      }

    }
  },
  mounted(){
    //  初始化动画效果库
    new this.$wow.WOW({
      live: true
    }).init();
    //绑定滚动事件
    window.addEventListener("scroll", this.handleScroll);

  },
  //销毁滚动事件
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .banner{
    width: 100%;
    height: auto;
    overflow: hidden;
    .banner-item{
      height: 80rem;
      width: 100%;
      overflow: hidden;
      position: relative;
      .banner-img{
        object-fit: cover;
        width: 100%;
        display: block;
        height: 100%;
        margin: 0 auto;
      }
      .banner-content{
        width: 36rem;
        height: auto;
        overflow: hidden;
        position: absolute;
        left: 50%;
        top: 18.8rem;
        margin-left: -18rem;
        z-index: 9;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        h1{
          font-weight: 800;
          font-size: 4.2rem;
          color: $white-color;
        }
        p{
          font-size: 1.8rem;
          font-weight: 500;
          color: $white-color;
          line-height: 2.4rem;
          margin: 2rem 0;
        }
        div{
          width: 11.1rem;
          height: 3.4rem;
          border: 0.1rem solid #F2F2F2;
          border-radius: 1.7rem;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          span{
            font-size: 1.4rem;
            font-weight: bold;
            color: $white-color;
            line-height: 2.4rem;
          }
          img{
            margin-left: 1rem;
            display: block;
            width: 0.8rem;
            height: 1rem;
          }
        }
      }
    }

  }
  .banner-footer{
    width: 100%;
    height: auto;
    overflow: hidden;
    background-color: #F6F6F6;
    .banner-footer-item{
      height: 14.8rem;
      display: flex;
      box-sizing: border-box;
      padding-left: 5rem;
      flex-direction: column;
      justify-content: center;
      .item-big{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;

        h1{
          font-size: 4rem;
          line-height: 1rem;
          font-weight: 600;
          color: $orange-color;
        }
        h1:after{
          content: "";
          display: block;
          width: 2rem;
          height: 0.3rem;
          background-color: $orange-color;
          transform: translateY(1.5rem);
        }
        span{
          font-size: 1.8rem;
          font-weight: 800;
          color: $orange-color;
        }
      }
      .item-text{
        margin-top: 3rem;
        p{
          font-size: 1.4rem;
          font-weight: 600;
          color: $text-color;
          line-height: 1.4rem;
        }

      }
    }
  }
  .title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5rem;
    div{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      h1{
        font-size: 2.8rem;
        line-height: 2.8rem;
        font-weight: bold;
        color: $black-color;
      }
      img{
        width: 6.1rem;
        height: 4.8rem;
        display: block;
        transform: translateX(-2rem);
      }
    }
    p{
      font-size: 1.4rem;
      font-weight: 400;
      color: $footer-color;
      text-align: center;
    }
  }
  .service-wrap{
    margin-bottom: 5rem;
    height: 70rem;
    //overflow: hidden;
  }
  .service-left{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    .service-item:first-of-type{
      width: 60%;
      .service-item-tag{
        span{
          width: 33% !important;
        }
      }
    }
    .service-item:nth-of-type(2),.service-item:nth-of-type(5){
      width: 40%;
    }
    .service-item:nth-of-type(3){
      width: 40%;
    }
    .service-item:nth-of-type(4){
      width: 60%;
    }
    .service-item:nth-of-type(3),.service-item:nth-of-type(4){
      .service-item-tag{
        span{
          width: 100% !important;
        }
      }
    }
  }
  .service-right{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    .service-item:first-of-type {
      width: 100%;
      height: 40%;
    }
    .service-item:nth-of-type(2){
      width: 100%;
      height: 60%;
    }
  }
  .service-item:hover>img{
    transform: scale(1.2);
  }
  .service-item{
    cursor: pointer;
    position: relative;
    overflow: hidden;
    img{
      display: block;
      width: 100%;
      height: 100%;
      transition: 0.5s;
    }
    .service-item-wrap{
      width: 100%;
      height: 100%;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      padding: 3rem 0 0 3rem;
      z-index: 9;
      .service-item-title{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        img{
          width: 2.3rem;
          height: auto;
          display: block;
          margin-right: 1rem;
        }
        h1{
          font-size: 2.2rem;
          font-weight: 500;
          color: $white-color;
          line-height: 2.2rem;
        }
      }
      .service-item-tag{
        margin-top: 3rem;
        margin-left: 5%;
        width: 95%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        span{
          width: 45%;
          display: block;
          font-size: 1.4rem;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 3rem;
        }
      }
    }
  }
//  客户案例
  .case{
    width: 100%;
    height: auto;
    overflow: hidden;
    background-color: $grey-color;
    .case-item{
      height: auto;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 1.2rem;
      margin: 1.2rem 0;
      .case-item-wrap{
        width: 100%;
        height: auto;
        overflow: hidden;
        background-color: $white-color;
        .case-item-img{
          width: 100%;
          height: auto;
          overflow: hidden;
          cursor: pointer;
          img{
            display: block;
            width: 100%;
            height: auto;
            transition: 0.5s;
          }
        }
        .case-item-img:hover>img{
          transform: scale(1.2);
        }
        .case-item-content{
          width: 90%;
          margin: 0 auto;
          img{
            display: block;
            margin: 3rem 0;
          }
          h3{
            font-size: 1.8rem;
            font-weight: bold;
            color: $black-color;
          }
          p{
            font-size: 1.4rem;
            font-weight: 400;
            color: $text-color;
          }
          .case-item-tag{
            width: 100%;
            border-top: 0.1rem solid #E8E8E8;
            margin: 5rem 0 3rem;
            padding: 2rem 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            span{
              padding: 0.3rem 1rem;
              border-radius: 1.4rem;
              margin-right: 1rem;
              font-size: 1.4rem;
              margin-bottom: 1rem;
            }
            span:first-of-type{
              background-color: $tag-one-color;
              color: #C5A6A6;
            }
            span:nth-of-type(2){
              background-color: $tag-two-color;
              color: #C1B39F;
            }
            span:nth-of-type(3){
              background-color: $tag-three-color;
              color: #9BA7B3;
            }

          }
        }

      }
    }
  }
  .more-case{
    width: 30rem;
    height: 6rem;
    background: $white-color;
    border-radius: 1rem;
    margin: 4rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span{
      font-size: 1.8rem;
      font-weight: bold;
      color: $footer-color;
    }
  }
  .customer{
    width: 100%;

    background: url("https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/gateonWeb/image/customer_back.png") no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    .customer-containe{
      margin: 0 auto;
      padding-bottom: 4rem;
      .customer-item{
        padding: 0.8rem 1.5rem;
        img{
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .news{
    width: 100%;
    height: auto;
    overflow: hidden;
    .news-left{
      height: auto;
      overflow: hidden;
      margin-bottom: 4rem;
      .news-item{
        cursor: pointer;
        border: 0.1rem solid #F2F2F2;
        .news-item-img{
          width: 100%;
          height: auto;
          overflow: hidden;
          img{
            display: block;
            width: 100%;
            height: auto;
            transition: 0.5s;
          }
        }
        .news-item-img:hover>img{
          transform: scale(1.2);
        }
        .news-item-content{
          width: 100%;
          box-sizing: border-box;
          padding: 1rem;
          h3{
            font-size: 1.8rem;
            font-weight: bold;
            color: $black-color;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 2rem;
            span{
              width: 3.8rem;
              height: 2.2rem;
              font-size: 1.4rem;
              font-weight: bold;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: $red-color;
              color: $white-color;
            }
          }
          p:first-of-type{
            font-size: 1.4rem;
            font-weight: 400;
            color: $text-color;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 0;
          }
          p:nth-of-type(2){
            font-size: 2rem;
            font-weight: bold;
            color: $footer-color;
            text-align: right;
            margin-bottom: 0;
          }
        }
      }

    }
    .news-right{
      height: auto;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .news-right-item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3.8rem;
        cursor: pointer;
        .new-right-item-img{
          width: 28%;
          height: auto;
          overflow: hidden;
          img{
            display: block;
            width: 100%;
            height: auto;
            transition: 0.5s;
          }
        }
        .new-right-item-img:hover>img{
          transform: scale(1.2);
        }
        .new-right-item-content{
          width: 69%;
          .news-right-item-content-title{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 0.1rem solid #E8E8E8;
            margin-bottom: 1.2rem;
            padding-bottom: 1.2rem;
            h3{
              width: 80%;
              font-size: 1.8rem;
              font-weight: bold;
              color: $black-color;
              overflow:hidden;
              text-overflow:ellipsis;
              white-space:nowrap;
              margin: 0;
            }
            p{
              font-size: 2rem;
              font-weight: bold;
              color: $footer-color;
              margin: 0;
            }
          }
          p{
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            font-size: 1.4rem;
            font-weight: 400;
            color: $text-color;

          }
        }
      }
    }
  }

  // md  平板设备
  @media only screen and (min-width: 992px){
    .service-wrap{
      height: 57.8rem !important;
    }
  }
  //  sm  手机设备
  @media only screen and (min-width: 768px){
    .service-wrap{
      height: auto;
    }
    .service-right{
      .service-item:first-of-type {
        width: 100%;
        height: auto;
      }
      .service-item:nth-of-type(2){
        width: 100%;
        height: auto;
      }
    }
  }
  //  xs  小屏手机设备
  @media only screen and (max-width: 767px){
    .service-item{
      width: 100% !important;
      height: auto !important;
    }
    .news-right-item-content-title{
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
    .banner-content{
      top: 30rem !important;
    }
  }
  // xl  屏幕较大设备
  @media only screen and (min-width: 1920px){

  }
  // lg  一般电脑设备
  @media only screen and (min-width: 1200px){
    .service-wrap{
      height: 70rem !important;
    }
  }
</style>
